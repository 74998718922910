
import React from 'react';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import Page3 from './Page3.js';
import App from '../App.js';
import PageSZ2 from './PageSZ2.js';
import PageSZ3 from './PageSZ3.js';
import PagePng from './PagePng.js';
import PagePansidong from './PagePansidong.js';
import AppStore from './AppStore.js';
import PageZhanJiang1 from './PageZhanJiang1.js';
import PageZhanJiang2 from './PageZhanJiang2.js';
import PageZhanJiang3 from './PageZhanJiang3.js';
import ChangeClothes from './ChangeClothes.js';
import PageHengqin from './PageHengqin.js';

const getRouter = () => (


    <BrowserRouter>
        <Switch>
            <Route path="/addHost" component={PageSZ2 }  />
            <Route path="/faceFusion" component={PagePng }  />
            <Route path="/facePansidong" component={PagePansidong }  />
            <Route path="/ChangeClothes" component={ChangeClothes }  />
            <Route path="/Answer" component={PageSZ3} />
            <Route path="/Home" component={App }  />
            <Route path="/UploadProject" component={Page3 }  />
            <Route path="/AppStore" component={AppStore}  />
            <Route path="/ZJeditActivity" component={PageZhanJiang3} />
            <Route path="/ZJeditBaseInfo" component={PageZhanJiang1} />
            <Route path="/ZJshow" component={PageZhanJiang2} />
            <Route path="/PageHengqin" component={PageHengqin} />
            <Route path="/" component={App }  />
        </Switch>
    </BrowserRouter>

    // <BrowserRouter>
    //     <Switch>
    //         <Route path="/facePansidong" component={PagePansidong }  />
    //     </Switch>
    // </BrowserRouter>
);
export default getRouter;
