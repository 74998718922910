import React , { useState }  from 'react';
import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
} from 'antd';
import '../App.less';
import { Link } from 'react-router-dom';
import VideoUploader from "./VideoUploader";
import PicturesWall from "./PicturesWall";
import REQUEST from '../util/request';
import API from '../util/api';
import { useSearchParams ,useParams,useLocation} from 'react-router-dom';

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

// const [searchParams, setSearchParams] = useSearchParams();

//

const classificationData = [];
class Page4 extends React.Component {

  formRef = React.createRef();

  constructor(){
    super();
    const self =this;

    this.formRef = React.createRef();


    this.state = {address: "",
    downloadFlag:false,
    videoUrl:"",
    winUrl:"",
    iosUrl:"",
    androidUrl:"",



    // projectId:this.getUrlParams('projectId',this.props.location.search),
    // userId:this.getUrlParams('userId',this.props.location.search),
  };

  }


 info = msg => {
   Modal.info({
   title: msg,

   onOk() {},
   });
 }

  componentDidMount () {

    this.formRef.current.setFieldsValue({
          reamrk: "视频资料如涉及传播版权请联系我们",
        })
    console.log(this.props.projectId);
    const sendData={}
    REQUEST.get(API.SELECTCLASS, sendData, (responseData)=>{

      let errorCode = responseData[0].errorCode;
      if (errorCode == "0000"){
        const classType = [];
        let resultCount = Number(responseData[0].resultCount);

            for (let i = 0; i < resultCount; i++) {
              console.log(resultCount);
              console.log(responseData[i].numId);
              console.log(responseData[i].classification);
              classType.push(<Option key={responseData[i].numId}>{responseData[i].classification}</Option>)

            }
            this.setState({classType:classType});

            console.log(this.state.classType);
          }
        });

    this.setState({productId:this.props.projectId});
    this.onSelectClick();

    // that.setState({ projectId:this.getUrlParams('projectId',this.props.location.search),
    // userId:this.getUrlParams('userId',this.props.location.search),});
    // console.log(useParams());


  //   window.AMap.plugin(['AMap.Autocomplete'], function() {
  //   var auto = new window.AMap.Autocomplete({
  //        input: "tipinput"
  //    });
  //    // auto.search(keywords);
  //  }
  // )
  // let auto = new window.AMap.Autocomplete({input:'tipinput'})
  // window.AMap.event.addListener(auto, "select", this.select);
  // this.onSelectClick();




}

select=(e)=>{
  console.log(e.poi);
  this.setState({address:e.poi.name,
  positionx1:e.poi.location.lng,
  positiony1:e.poi.location.lat})

  //更新位置信息
  const sendData = {projectId: this.getUrlParams('projectId',this.props.location.search),
  userId: this.getUrlParams('userId',this.props.location.search),
  positionx1:this.state.positionx1,
  positiony1:this.state.positiony1,
  positionx2:this.state.positionx1 + 0.01,
  positiony2:this.state.positiony1 + 0.01,

  };
  REQUEST.get(API.UPDATEPOSITION, sendData, (responseData)=>{
    let errorCode = responseData[0].errorCode;
    console.log(responseData[0]);

    // const fileList = [];
    // if (errorCode == "0000"){
    //   this.info("概要信息修改成功！");
    // }
});




}
getUrlParams =(name, str) => {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return "";
}

updateProject=()=>{

  console.log(this.formRef.current.getFieldsValue("productName")["productName"]);
  const sendData =
  {
  productName:this.formRef.current.getFieldsValue("productName")["productName"],
  reamrk:this.formRef.current.getFieldsValue("reamrk")["reamrk"],
  useScenario:this.formRef.current.getFieldsValue("useScenario")["useScenario"],
  explain:this.formRef.current.getFieldsValue("explain")["explain"],
  numId:this.formRef.current.getFieldsValue("classification")["classification"],

  };

  if (this.state.productId == ""){
    REQUEST.get(API.ADDPRODUCT, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      console.log(responseData[0]);

      const fileList = [];
      if (errorCode == "0000"){
        this.info("产品信息登记成功！");
        this.setState({downloadFlag:true,
        productId: responseData[0].productId});
      }
  });
  }
  else{
    sendData["productId"] = this.state.productId;
    REQUEST.get(API.UPDATEPRODUCT, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      console.log(responseData[0]);

      const fileList = [];
      if (errorCode == "0000"){
        this.info("产品信息修改成功！");
      }
  });
  }

}

onChange = value => { console.log(value.target.value); this.setState({address:value.target.value})}

onSelectClick=() =>{

  if(this.props.projectId!= ""){
    const sendData = {product: this.props.projectId};
    REQUEST.get(API.DOSELECTPRODUCT, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      console.log(responseData[0]);

      const fileList = [];
      if (errorCode == "0000" && responseData[0].resultCount > 0){

        console.log(this.state.downloadFlag);
        // this.setState({address:responseData[0].address.toString(),downloadFlag:true});
        //获取表单内容
        this.formRef.current.setFieldsValue({
              productName: responseData[0].productName.toString(),
              reamrk: responseData[0].reamrk.toString(),
              useScenario:responseData[0].useScenario.toString(),
              explain:responseData[0].explain.toString(),
              // className: responseData[0].className.toString(),
              classification: responseData[0].classification.toString(),


            });
        this.setState({productName:responseData[0].productName.toString(),
          reamrk:responseData[0].reamrk.toString(),
          classification: responseData[0].classification.toString(),
          useScenario:responseData[0].useScenario.toString(),
          explain:responseData[0].explain.toString(),

        });
        }
        console.log(this.state.downloadFlag);


    });


    REQUEST.get(API.GETMARKPOINTPNG, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      console.log(responseData[0]);

      const fileList = [];
      if (errorCode == "0000"){
        const resultCount = responseData[0].resultCount;
        for (let i = 0; i < resultCount; i++) {

            console.log(responseData[i].address);
            if (responseData[i].picType == "01"){
              this.setState({videoUrl:responseData[i].address})
            }
            else if (responseData[i].picType == "02"){
              this.setState({winUrl:responseData[i].address})
            }
            else if (responseData[i].picType == "03"){
              this.setState({iosUrl:responseData[i].address})
            }
            else if (responseData[i].picType == "04"){
              this.setState({androidUrl:responseData[i].address})
            }
            else if (responseData[i].picType == "05"){
              this.setState({pngUrl:responseData[i].address})
            }

        }
          this.setState({downloadFlag:true})

        }

        console.log(this.state.downloadFlag);


    });
  }

  }

render() {
  // var keywords = document.getElementById("tipinput").value;


  return(
    <div>
    <Form style={{marginTop:100}} ref={this.formRef}  labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
      <Form.Item label="产品名称" name="productName">
        <Input min={1} max={50}  />

      </Form.Item>
      <Form.Item label="产品分类" name="classification">
      <Select defaultValue="" >
         {this.state.classType}
      </Select>
      </Form.Item>
      <Form.Item label="使用场所" name="useScenario">
        <TextArea rows={4}   />
      </Form.Item>
      <Form.Item label="说明" name="explain">
        <TextArea rows={4}   />
      </Form.Item>
      <Form.Item label="备注" name="reamrk">
        <TextArea rows={4}   />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8, offset: 8 }} >
      {this.state.downloadFlag?
        <Space>
          <PicturesWall buttonName="首页图片" uploadType='image/*' maxFileCount='1' ImageType='05' productId={this.state.productId} url={[this.state.pngUrl]}/>
          <PicturesWall buttonName="视频上传" uploadType='video/*' maxFileCount='1' ImageType='01' productId={this.state.productId} url={[this.state.videoUrl]}/>
          <PicturesWall buttonName="安装包上传" uploadType='zip/*' maxFileCount='1' ImageType='02' productId={this.state.productId}  url={[this.state.winUrl]}/>
          <PicturesWall buttonName="IOS端上传" uploadType='image/*' maxFileCount='1' ImageType='03' productId={this.state.productId}  url={[this.state.iosUrl]}/>
          <PicturesWall buttonName="Android端上传" uploadType='image/*' maxFileCount='1' ImageType='04' productId={this.state.productId}  url={[this.state.androidUrl]}/>
        </Space>:null}

      </Form.Item>
      <Form.Item wrapperCol={{ span: 8, offset: 8 }} >
        <Space>
      <Button  type="primary" onClick={this.updateProject}>
        提交
      </Button>

        </Space>
    </Form.Item>
    </Form>

    </div>
)}

}



export default Page4;
