import '../App.less';
import React , { useState, useEffect }  from 'react';
import REQUEST from '../util/request';
import API from '../util/api';
import ReactWebsocket from './ReactWebsocket'
import { Carousel,Spin,Modal,Rate,Row ,Col ,Space,Typography,List,Button,Radio   } from 'antd';
import URI from 'urijs';
import wx from 'weixin-js-sdk';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactEcharts from 'echarts-for-react';
import 'echarts-liquidfill/src/liquidFill.js'

const w = document.documentElement.clientWidth;
const h =  document.documentElement.clientHeight;
const isLandscape = (w > h);
const width = isLandscape ? h: w;
const height = isLandscape ? w: h;


const { Title } = Typography;
const { confirm } = Modal;


const Schedule = (props) =>{

  return (<ReactEcharts style={{ marginTop:'-5%' }} option={{series: [{type: 'liquidFill', radius: props.radius, data: [props.peoplenum/props.totalPeople], color:['#ffc796'],
  label: {
     position: ['30%'],
     formatter: (params) => {  return  params.data*props.totalPeople},
     fontSize: 40,
     color: '#ffc796',
     position: 'inside'
 },

 outline: {
     show: true,
     borderDistance: 8,

     itemStyle: {
         color: 'none',
         style:'dashed',
         borderColor: '#ffc796',
         borderWidth: 2,
         shadowBlur: 0,
         shadowColor: 'rgba(0, 0, 0, 0.25)'
     }
 },

  backgroundStyle: {
       color: '#FFFF',
       borderColor: '#ffc796',
       borderWidth: 0,
       backgroundColor: 'rgba(1, 1, 1, 1)',
       shadowColor: 'rgba(1, 1, 1, 1)',
       shadowBlur: 0
   },}]} }/>);
}


const Schedule2 = (props) =>{

  return (<ReactEcharts style={{ marginTop:'-10%',height: props.height}} option={{series: [{type: 'liquidFill', radius: props.radius, data: [props.peoplenum/props.totalPeople], color:[props.color],
  label: {
     position: ['30%'],
     formatter: (params) => {  return  params.data*props.totalPeople},
     fontSize: 20,
     color: props.color,
     position: 'inside'
 },

 outline: {
     show: true,
     borderDistance: 2,

     itemStyle: {
         color: 'none',
         style:'dashed',
         borderColor: props.color,
         borderWidth: 2,
         shadowBlur: 0,
         shadowColor: 'rgba(0, 0, 0, 0.25)'
     }
 },

  backgroundStyle: {
       color: '#FFFF',
       borderColor: '#ffc796',
       borderWidth: 0,
       backgroundColor: 'rgba(1, 1, 1, 1)',
       shadowColor: 'rgba(1, 1, 1, 1)',
       shadowBlur: 0
   },}]} }/>);
}


class PageSZ2 extends React.Component{

  constructor(){
    super();
    this.state = {
      webUrl:"",
      PageFlag: '0',
      themeId:"1",
      number:"5",
      opacity1:"0.8",
      opacity2:"0.8",
      opacity3:"0.8",
      opacity4:"0.8",
      projectId:"",
      sessionId:"",
      answerMode:"1",
      peoplenum:0,
      peoplenum1:0,
      peoplenum2:0,
      peoplenum3:0,
      peoplenum4:0,
      peoplenum5:0,
      flagId:"",
      answer:"",
      yes:["","", "", "", "","","", "", "", ""],
      answerData:[],
      answerIndex:0,
      fraction:60,
      userTime:0,
      modalVisible : false,
      modalMseeage: "",
      opacity:1,
      groupImage: "椭圆.png",
      closeImage:"结束答题.png",
      headimgurl:"https://t7.baidu.com/it/u=1063451194,1129125124&fm=193&f=GIF",
      data:[{email:11,themeId:1},{email:12,themeId:2},{email:13,themeId:3},{email:14,themeId:4},{email:15,themeId:5},{email:16,themeId:6},{email:17,themeId:7},{email:18,themeId:8},{email:19,themeId:9},{email:20,themeId:10}]
    };
  }


  setOpacity=()=>{

    if (this.state.opacity < 1){
      this.setState({opacity:this.state.opacity+0.05});
      setTimeout(this.setOpacity, 20);
    }

  }


  ceshi=(peopleId)=> {
    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.indexOf('micromessenger') < 0) return false
    const sendData = {
      projectId: this.state.projectId ,
      sessionId:this.state.sessionId ,
      peopleId: peopleId,
      code:this.state.code,
      state:this.getUrlParams('state',this.props.location.search),
    }

    REQUEST.get(API.GETWXINFO, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      const newData = [];
      if (errorCode != "0000"){
          this.info(responseData[0].errorMsg)
        }
      else{

        console.log(responseData);

        wx.config({
          debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: responseData[0].appId, // 必填，公众号的唯一标识
          timestamp: responseData[0].timestamp, // 必填，生成签名的时间戳
          nonceStr: responseData[0].nonceStr, // 必填，生成签名的随机串
          signature: responseData[0].signature,// 必填，签名，见附录1
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
        });

        console.log('okkk')
        wx.ready(function () {

          wx.updateAppMessageShareData({
            debug: true,
            title: '资讯菜价', // 分享标题
            desc: '农产品价格交易资讯', // 分享描述
            link:  document.location.href,
            imgUrl: 'https://t7.baidu.com/it/u=1063451194,1129125124&fm=193&f=GIF', // 分享图标
            // type: '', // 分享类型,music、video或link，不填默认为link
            // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              console.log('okkk11111111111111111111111')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })

          //分享到朋友圈
          wx.updateTimelineShareData({
            title: '资讯菜价', // 分享标题
            desc: '农产品价格交易资讯', // 分享描述
            link: document.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://t7.baidu.com/it/u=1063451194,1129125124&fm=193&f=GIF', // 分享图标
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
        });
      }
    })


  }


  generateGetCodeUrl =(redirectURL)=> {

    // return new URI("https://open.weixin.qq.com/connect/oauth2/authorize")
    //     .addQuery("appid", "wx39d234dccb52b122")
    //     .addQuery("redirect_uri", document.location.href)
    //     .addQuery("response_type", "code")
    //     .addQuery("scope", "snsapi_userinfo")
    //     .addQuery("state", "STATE")
    //     .addQuery("connect_redirect", "1")
    //     .hash("wechat_redirect")
    //     .toString();


    return new URI("https://open.weixin.qq.com/connect/oauth2/authorize")
        .addQuery("appid", "wxb650cd27b8ec8e4d")
        .addQuery("redirect_uri", document.location.href)
        .addQuery("response_type", "code")
        .addQuery("scope", "snsapi_userinfo")
        .addQuery("state", "STATE")
        .addQuery("connect_redirect", "1")
        .hash("wechat_redirect")
        .toString();

 };



  componentWillMount(){

    document.title='答题系统';
    if (this.getUrlParams('code',this.props.location.search) != ""){
      // this.setState({
      //     code:this.getUrlParams('code',this.props.location.search),
      //   })
      //
      //   const sendData = {code: this.getUrlParams('code',this.props.location.search),
      //   appid:"wx39d234dccb52b122" ,
      //   secret: "e7494dfc95a306ccb62cfd294a19131a",
      //   grant_type:"authorization_code"
      //  }
      //
      //
      //   REQUEST.get("https://api.weixin.qq.com/sns/oauth2/access_token", sendData, (responseData)=>{
      //     console.log(responseData);

          this.setState({
              projectId:this.getUrlParams('projectId',this.props.location.search),
              sessionId:this.getUrlParams('sessionId',this.props.location.search),
              code:this.getUrlParams('code',this.props.location.search),
              webUrl:this.getUrlParams('projectId',this.props.location.search) == "shenzhen001"?'ws://39.105.202.145:8004/Answer/ReactWebsocketHost/':'ws://39.105.202.145:8004/Answer/ReactWebsocketHost2/'
          });
          this.addHost();
          // this.ceshi();
  //   }
  // )
  }
    else{
      document.location = this.generateGetCodeUrl(document.location.href);
    }

    // this.setState({
    //     projectId:this.getUrlParams('projectId',this.props.location.search),
    //     sessionId:this.getUrlParams('sessionId',this.props.location.search),})


  }

  getUrlParams =(name, str) => {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = str.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return "";
    }


    onClick1=()=>{

      this.setState({PageFlag:"1",
      opacity:0});
      setTimeout(this.setOpacity, 100);
    }

    onClick2=()=>{

      if (this.state.flagId == ""){
        this.info("请选择题组！");
        return;
      }
      this.onSelectClick();
      this.setState({PageFlag:"3",opacity:0});
      setTimeout(this.setOpacity, 100);
    }

    onClick3=()=>{

      if (this.state.themeId == ""){
        this.info("请选择主题！");
        return;
      }

      this.setState({PageFlag:"3",
      opacity:0});
      setTimeout(this.setOpacity, 100);
    }

    onClick4=()=>{

      this.setState({PageFlag:"4",
      opacity:0});
      setTimeout(this.setOpacity, 100);
    }

    setResult2=()=>{
      const sendData = {projectId: this.state.projectId ,
      sessionId:this.state.sessionId ,
      themeId: this.state.themeId,
      peopleId:this.state.peopleId,
    }
      REQUEST.get(API.SETRESULT, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{
          this.setState({closeImage:"退出此页面.png"});
        }
      });

    };

    setResult=()=>{

      if (this.state.closeImage == "结束答题.png"){
        Modal.confirm({
        title: '是否提前结束答题？',
        width:'80%',
        okText:"确认",
        cancelText:"取消",
        onOk:() => {

          this.setResult2()
        },

      });
      }
      else{
        this.closeWeChatWeb();
      }

  };


 closeWeChatWeb=()=> {
   // JSSDK的方式
    wx.closeWindow();
  }

    onClick5=()=>{

      if (this.state.answerMode == ""){
        this.info("请选择答题模式！");
        return;
      }

      const sendData = {projectId: this.state.projectId ,
      sessionId:this.state.sessionId ,
      themeId: this.state.themeId,
      peopleId:this.state.peopleId,
      number:this.state.number,
      answerMode:this.state.answerMode,}
      REQUEST.get(API.SETANSWERMODE, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{
          const newData = [];
          console.log(responseData);
          let resultCount = Number(responseData[0].resultCount);
              for (let i = 0; i < resultCount; i++) {
                newData.push({
                  key: responseData[i].answerId,
                  describe: (i+1) + "、"+responseData[i].describe,
                  TitleURL: responseData[i].TitleURL,
                  optionA:  "A：" + responseData[i].optionA,
                  optionB:  "B：" + responseData[i].optionB,
                  optionC : responseData[i].optionC ==""? "":"C：" + responseData[i].optionC,
                  optionD : responseData[i].optionD==""?"":"D：" + responseData[i].optionD,
                  answer: responseData[i].answer,
                  status: responseData[i].status,
                  postscriptType:responseData[i].postscriptType,
                  productId:responseData[i].projectId,
                  themeId:responseData[i].themeId,
                  answerId:responseData[i].answerId,
                });
              }
            this.setState({answerData:newData});
        }
      });
      this.setState({PageFlag:"5",
      opacity:0});

      if (this.state.answerMode == "2"){
        this.setState({groupImage:"红组.png"});
      }

      setTimeout(this.setOpacity, 100);

    }

    onClick6=()=>{
      console.log("-------------------0000000000000");
      const sendData = {
        projectId: this.state.projectId ,
        sessionId:this.state.sessionId ,
        themeId: this.state.themeId,
      }
      REQUEST.get(API.SETANSWERSTART, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{
          this.setState({PageFlag:"9",
          opacity:0});
          setTimeout(this.setOpacity, 100);
          setTimeout(this.countTime, 1000);
        }
      });
    }




    onClick7=()=>{
      const sendData = {
        projectId: this.state.projectId ,
        sessionId:this.state.sessionId ,
        themeId: this.state.answerData[this.state.answerIndex]["themeId"],
        peopleId:this.state.peopleId,
        answer:this.state.answer,
        questionId:this.state.answerData[this.state.answerIndex]["answerId"],
      }
      REQUEST.get(API.SETANSWERKEY, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{
          // if (this.state.answerMode == "1"){
            let yesList = this.state.yes;
            yesList[this.state.answerIndex] = responseData[0].yesFlag;


            const lastFlag = responseData[0].lastFlag;

            this.setState({
              answer:this.state.answerData[this.state.answerIndex]["answer"],
              yes:yesList,})

            if (responseData[0].yesFlag == "yes"){
              this.setState({
                      modalVisible:true,
                      fraction:60,
                      modalMseeage: "你答对该题，请准备下一题！",})
              setTimeout(this.setVisibleFalse, 4000);
            }
            else{
              this.setState({
                      modalVisible:true,
                      fraction:60,
                      modalMseeage: "你答错该题，正确答案为：" + this.state.answerData[this.state.answerIndex]["answer"] + "，请准备下一题！",})
              setTimeout(this.setVisibleFalse, 4000);
            }


            if (lastFlag == "true"){
              setTimeout(this.setLast, 4000);
            }
            else{
              setTimeout(this.setNext, 4000);
            }
          }


        // }
      });
    }

    countTime=()=>{

      if (this.state.PageFlag == "6"){
        var userTime = this.state.userTime + 1;
        var fraction = this.state.fraction -1;

        if (fraction<=0){

          if (fraction==0){
            this.onClick7();
          }
          else{
            fraction = 0;
          }

        }



        this.setState({userTime :userTime,
          fraction:fraction,
          });
        setTimeout(this.countTime, 1000);

      }
    }

    onSelectClick1=(index)=>{

      this.setState({themeId:index});

      const sendData = {projectId: this.state.projectId ,
      sessionId:this.state.sessionId ,
      themeId: index}
      REQUEST.get(API.CHOOSETHEMEID, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{

        }
      });

    }

    onSelectClick=()=>{

      const sendData = {
        projectId: this.state.projectId ,
        sessionId:this.state.sessionId ,
        themeId: this.state.themeId
      }
      REQUEST.get(API.CHOOSETHEMEID, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{

        }
      });

    }

    onSelectClick2=(option)=>{
      console.log('radio checked', option.target.value)
      this.setState({answer:option.target.value});
    }

    setOpacity1=()=>{
      this.setState({opacity1:"1",
      opacity2:"0.6"});
      this.chooseQuestion("0");
    }

    setOpacity2=()=>{
      this.setState({opacity1:"0.6",
      opacity2:"1"});
      this.chooseQuestion("1");
    }
    setOpacity3=()=>{
      this.setState({opacity3:"1",
      opacity4:"0.6",
    answerMode:"1",});
    }

    setOpacity4=()=>{
      this.setState({opacity3:"0.6",
      opacity4:"1",
    answerMode:"2",});
    }

    OnNumAdd=()=>{
      if (this.state.number < 5){
        this.setState({number:this.state.number+1});
      }
    }

    OnNumReduce=()=>{
      if (this.state.number > 2){
        this.setState({number:this.state.number-1});
      }
    }

    //主持人加入
    addHost=()=>{
      const sendData = {projectId: this.getUrlParams('projectId',this.props.location.search) ,
      code:this.getUrlParams('code',this.props.location.search) ,
      sessionId:this.getUrlParams('sessionId',this.props.location.search)}
      REQUEST.get(API.ADDHOST, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
            this.setState({PageFlag: '0',
        });
          }
        else{
            this.setState({peopleId: responseData[0].peopleId,
              headimgurl:responseData[0].headimgurl,
              PageFlag:"1",
              });

            if (responseData[0].pageFlag != ""){
              console.log(responseData);
              this.setState({PageFlag:responseData[0].pageFlag,
                themeId: responseData[0].themeId,
                peopleId:responseData[0].peopleId,
                number:responseData[0].number,
                answerMode:responseData[0].answerMode,

               });
            }

            // this.ceshi(responseData[0].peopleId);
        }
      });
    }

    getResult=()=>{
      const sendData = {
        projectId: this.state.projectId ,
        sessionId:this.state.sessionId ,
        peopleId:this.state.peopleId,

      }
      REQUEST.get(API.GETRESULT, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{
          const completeFlag =responseData[0].completeFlag

          if (completeFlag == "true"){
            const firstFlag =responseData[0].firstFlag;

            if (firstFlag == "true"){
              setTimeout(this.setState({PageFlag:8}), 4000);

            }
            console.log(firstFlag);
          }
          else{
            setTimeout(this.getResult(), 1000);
          }
        }

    })
  }

    //选择十个明确或四大精神
    chooseQuestion=(flagId)=>{

      this.setState({flagId: flagId,
      themeId:flagId,});
      const sendData = {projectId: this.state.projectId ,
      sessionId:this.state.sessionId ,
      flagId: flagId}
      REQUEST.get(API.CHOOSEQUESTION, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
            this.setState({flagId: "",});
          }
        else{
            // this.setState({flagId: flagId,});
        }
      });
    }

    info = msg => {
      Modal.info({
        title: msg,
        centered:true,
        width:'80%',
        onOk() {},
      });
    }

    info2 = msg => {
      Modal.info({
        title: msg,
        centered:true,
        width:'80%',
        okButtonProps:{disabled:true},
      });
    }

    onMessage=value=>{
      console.log(value);

      if (value == "OK"){
        return;
      }
      const responseData = JSON.parse(value);

      const interface1 =responseData[0].interface
      if (this.state.answerMode == "2" && interface1 == "007"){

        this.setState({modalVisible:true,
        modalMseeage:"选手" +responseData[0].name+ "已抢答该题，请稍后！",
        nowYesFlag:responseData[0].yesFlag,
        nowPeoPleId:responseData[0].peopleId,
        nowLastFlag:responseData[0].lastFlag,
        nowName:responseData[0].name});

        setTimeout(this.setNext1, 2000);

      }
      else if (interface1 == "005")
      {
        const answerMode =responseData[0].answerMode;
        if (answerMode == "1"){
          this.setState({peoplenum:responseData[0].addCount})
        }
        else{
          const groupId = responseData[0].groupId;
          if (groupId == "1"){
            this.setState({peoplenum1:responseData[0].addCount})
          }
          else if (groupId == "2"){
            this.setState({peoplenum2:responseData[0].addCount})
          }
          else if (groupId == "3"){
            this.setState({peoplenum3:responseData[0].addCount})
          }
          else if (groupId == "4"){
            this.setState({peoplenum4:responseData[0].addCount})
          }
          else if (groupId == "5"){
            this.setState({peoplenum5:responseData[0].addCount})
          }
        }
      }
      else if (interface1 == "008"){
        const peopleId  =responseData[0].peopleId;
        console.log(this.state.peopleId);

        let resultCount = Number(responseData[0].resultCount);
        for (let i = 0; i < resultCount; i++) {
          if (this.state.peopleId.toString() == responseData[i].peopleId.toString()){

            this.setState({rank:i+1});
            //设置名次
            setTimeout(this.setRank, 2000);
          }

        }

        if (this.state.peopleId.toString() == peopleId.toString()){

          if (this.state.answerMode == "2"){
            setTimeout(this.setFirst, 15000);
          }
          else{
            setTimeout(this.setFirst, 15000);
          }
        }
      }
    }


    setNext1=()=>{

      this.setState({
        modalVisible:false,})

      setTimeout(this.setNext(), 100);
    }

    setLast=()=>{

        this.setState({
          answer:"",
          PageFlag:"7",
          opacity:0,
        });
        setTimeout(this.setOpacity, 100);
    }

    setNext=()=>{
      this.setState({
        answer:"",
        opacity:0,
        fraction:60,
        answerIndex:this.state.answerIndex + 1});
        setTimeout(this.setOpacity, 50);


      // if (this.state.nowYesFlag == "yes"){
      //
      //
      //     if (this.state.nowLastFlag == "true"){
      //       this.setState({
      //         modalVisible:false,
      //       })
      //       setTimeout(this.setVisibleFalse, 500);
      //     }
      //     else{
      //       this.setState({
      //         modalVisible:true,
      //         fraction:60,
      //         modalMseeage:"选手" + this.state.nowName + "答对该题，请准备下一题！"});
      //       setTimeout(this.setVisibleFalse, 4000);
      //     }
      //
      // }
      // else{
      //   this.setState({
      //     modalVisible:true,
      //     fraction:60,
      //     modalMseeage:"选手" + this.state.nowName + "答错该题，请继续抢答！"});
      //     setTimeout(this.setVisibleFalse, 4000);
      // }
    }


    setVisibleFalse=()=>{
      if (this.state.nowYesFlag == "yes"){
        let yesList = this.state.yes;

        if (this.state.nowPeoPleId = this.state.peopleId){
          yesList[this.state.answerIndex] = this.state.nowYesFlag;

          this.setState({fraction:this.state.fraction + 10})
        }
        else{
          yesList[this.state.answerIndex] = "no";
        }

        const lastFlag = this.state.nowLastFlag;
        if (lastFlag == "true"){
          this.setState({yes:yesList,
            answer:"",
            PageFlag:"7",
            opacity:0,
          });
          setTimeout(this.setOpacity, 50);
          //this.getResult();
        }
        else{
          this.setState({yes:yesList,
            answer:"",
            opacity:0,
            answerIndex:this.state.answerIndex + 1});
            setTimeout(this.setOpacity, 50);
        }
      }

      this.setState({modalVisible:false,})
    }

    setFirst=()=>{
      this.setState({PageFlag: "10",
        opacity:0,
        answer:"",});
        setTimeout(this.setOpacity, 50);
    }

    setRank=()=>{
      this.setState({PageFlag: "8",
        opacity:0,});
        setTimeout(this.setOpacity, 50);
    }

    onOpen=value=>{
      console.log("111111111111111111111");
      this.sendMessage(this.state.sessionId);
    }

    onClose=()=>{
      console.log("111111111111111111112");
    }


    sendMessage=(message)=>{
      this.refWebSocket.sendMessage(message);
    }

    formatSeconds=(value)=> {
        var secondTime = parseInt(value);// 秒
        var minuteTime = 0;// 分
        var hourTime = 0;// 小时
        if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
            //获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60);
            //获取秒数，秒数取余，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            //如果分钟大于60，将分钟转换成小时
            if(minuteTime > 60) {
                //获取小时，获取分钟除以60，得到整数小时
                hourTime = parseInt(minuteTime / 60);
                //获取小时后取余的分，获取分钟除以60取余的分
                minuteTime = parseInt(minuteTime % 60);
            }
            console.log(secondTime);
        }
        var result = "" + parseInt(secondTime)<10? ("0" + parseInt(secondTime)):parseInt(secondTime);

        if(minuteTime > 0) {
          result = "" + (parseInt(minuteTime)<10? ("0" + parseInt(minuteTime)):(parseInt(minuteTime)))+ ":" + result;
        }
        else{
          result = "00" + ":" + result;
        }
        if(hourTime > 0) {
          result = "" + (parseInt(hourTime)<10? ("0" + parseInt(hourTime)):(parseInt(hourTime))) + ":" + result;
        }
        else{
          result = "00" + ":" + result;
        }

        return result;
    }

    render(){
            return(

              <div style={{backgroundcolor:'#fffdf8'}}>
              {this.state.webUrl !=""?
                <ReactWebsocket
                  url={this.state.webUrl}
                  onMessage={this.onMessage} //接受信息的回调
                  onOpen={this.onOpen} //websocket打开
                  onClose={this.onClose} //websocket关闭
                  reconnect={true}
                  debug={true}
                  ref={Websocket => {
                  this.refWebSocket = Websocket;
                  }}
                />:null}
               {  this.state.PageFlag == "4"   ?
               <div>
               <img style={{width: width*0.4, height: 'auto', position: 'absolute', top:'10%', left:width*0.3 }} src={require('../img/' + this.state.groupImage)}>
               </img>
               <img style={{width: width*0.38, height: width*0.38, position: 'absolute', top:'10.6%', left:width*0.31 , borderRadius:width*0.19}} src={this.state.headimgurl}>
               </img>
               </div>:null}
               {this.state.PageFlag == "1" ? <div style={{opacity: this.state.opacity, backgroundColor:'#fffdf8', height:height}} >
               <img style={{width: width, height: 'auto', }} src={require('../img/党徽.jpg')}>
               </img>
               <img style={{width: width*0.3, height: 'auto', marginTop:'10%', marginLeft:width*0.35 }} src={require('../img/请选择题组.png')}>
               </img>
               <img style={{width: width*0.96, height: 'auto', position: 'absolute',  top:'62%', left:width*0.02}} src={require('../img/题组外框.png')}>
               </img>
               <img style={{width: width*0.37, height: 'auto',  position: 'absolute', top:'66%', left:width*0.1}} src={this.state.flagId == "0"? require('../img/十个明确-选中.png'):require('../img/十个明确.png')} onClick= { ()=> this.chooseQuestion("0")}>
               </img>
               <img style={{width: width*0.37, height: 'auto',  position: 'absolute', top:'66%', left:width*0.51}} src={this.state.flagId == "1"? require('../img/精神谱系-选中.png'):require('../img/精神谱系.png')} onClick={()=> this.chooseQuestion("1")}>
               </img>
               <img style={{width: width*0.37, height: 'auto',  position: 'absolute', top:'76%', left:width*0.1 }} src={this.state.flagId == "2"? require('../img/深圳相关-选中.png'):require('../img/深圳相关.png')} onClick={()=> this.chooseQuestion("2")}>
               </img>
               <img style={{width: width*0.37, height: 'auto',  position: 'absolute', top:'76%', left:width*0.51}} src={this.state.flagId == "3"? require('../img/混合题组-选中.png'):require('../img/混合题组.png')} onClick={()=> this.chooseQuestion("3")}>
               </img>
               <img style={{width: width*0.2, height: 'auto', marginTop:'70%', marginLeft:width*0.4, }} src={require('../img/确定.png')} onClick={this.onClick2}>
               </img>
               <div style={{height: height*0.05,backgroundcolor:'#fffdf8'}} ></div>
               </div >:null}
               {this.state.PageFlag == "3" ? <div style={{opacity: this.state.opacity, backgroundColor:'#fffdf8', height:height}}>
               <img style={{width: width, height: 'auto' }} src={require('../img/党徽2.jpg')}>
               </img>
               <img style={{width: width*0.30, height: 'auto',  marginTop:'5%', marginLeft:width*0.35 }} src={require('../img/请选择答题模式.png')}>
               </img>
               <img style={{width: width*0.54, height: 'auto',  marginTop:'5%', marginLeft:width*0.23}} src={this.state.answerMode == "1"? require('../img/自由模式-选中.png'):require('../img/自由模式.png')} onClick={this.setOpacity3}>
               </img>
               <img style={{width: width*0.54, height: 'auto', marginTop:'5%', marginLeft:width*0.23}} src={this.state.answerMode == "2"? require('../img/对战模式-选中.png'):require('../img/对战模式.png')} onClick={this.setOpacity4}>
               </img>
               {this.state.answerMode == "2"?
               <div>
               <Row style={{ marginTop:'5%' ,textAlign: 'center',}} justify="center" align="middle">
               <Col span={2}>
               <img style={{width: width*0.04, height: 'auto',  }} src={require('../img/向左.png')} onClick={this.OnNumReduce}>
               </img></Col>
               <Col span={6}>
               <h style={{ fontSize:60,  color:'#996c33'}} >
               {this.state.number}
               </h>
               </Col>
               <Col span={2}>
               <img style={{width: width*0.04, height: 'auto', }} src={require('../img/向右.png')} onClick={this.OnNumAdd}>
               </img></Col>

                </Row>
               <img style={{width: width*0.14, height: 'auto', marginTop:'1%', marginLeft:width*0.43 }} src={require('../img/设置组数.png')}>
               </img>
               </div>:null}
               <Space>
               <img style={{width: width*0.2, height: 'auto',marginTop:'10%', marginBottom:'10%',marginLeft:width*0.4  }} src={require('../img/确定.png')} onClick={this.onClick5}>
               </img>
               <img style={{width: width*0.12, height: 'auto',  marginTop:'10.5%', marginLeft:width*0.2 }} src={require('../img/返回.png')} onClick={this.onClick1}>
               </img>
               </Space>
               </div>:null}
               {this.state.PageFlag == "5" ? <div style={{  textAlign: 'center', alignItems:'center',opacity: this.state.opacity, backgroundColor:'#fffdf8', height:height}}>
               <img style={{width: width, height: 'auto' }} src={require('../img/党徽2.jpg')}>
               </img>
               <img style={{width: width*0.25, height: 'auto',  marginTop:'10%' }} src={require('../img/等待参与人数.png')}>
               </img>
               {this.state.answerMode == "1" ?<Schedule totalPeople={50} radius='60%' peoplenum={this.state.peoplenum}/>:
               this.state.number==5?
                <div>
                <Row style={{ marginLeft:width*0.27, height: '30%', width:width*0.46}}>
                  <Col span={12}><Schedule2 height={height*0.18} color='#ff2993' totalPeople={10} radius='80%' peoplenum={this.state.peoplenum1}/></Col>
                  <Col span={12}><Schedule2 height={height*0.18} color='#ffb400' totalPeople={10} radius='80%' peoplenum={this.state.peoplenum2}/></Col>
                </Row>
                <Row  style={{ marginLeft:width*0.15, height: '30%', width:width*0.7}}>
                  <Col span={8}><Schedule2 height={height*0.18} color='#00d2ff' totalPeople={10} radius='80%' peoplenum={this.state.peoplenum3}/></Col>
                  <Col span={8}><Schedule2 height={height*0.18} color='#96ff00' totalPeople={10} radius='80%' peoplenum={this.state.peoplenum4}/></Col>
                  <Col span={8}><Schedule2 height={height*0.18} color='#9e31c2' totalPeople={10} radius='80%' peoplenum={this.state.peoplenum5}/></Col>
                </Row>
              </div>:this.state.number==4?
               <div>
               <Row style={{ marginLeft:width*0.27, height: '30%', width:width*0.46}}>
                 <Col span={12}><Schedule2 height={height*0.18} color='#ff2993' totalPeople={13} radius='80%' peoplenum={this.state.peoplenum1}/></Col>
                 <Col span={12}><Schedule2 height={height*0.18} color='#ffb400' totalPeople={13} radius='80%' peoplenum={this.state.peoplenum2}/></Col>
               </Row>
               <Row  style={{ marginLeft:width*0.27, height: '30%', width:width*0.46}}>
                 <Col span={12}><Schedule2 height={height*0.18} color='#00d2ff' totalPeople={13} radius='80%' peoplenum={this.state.peoplenum3}/></Col>
                 <Col span={12}><Schedule2 height={height*0.18} color='#96ff00' totalPeople={13} radius='80%' peoplenum={this.state.peoplenum4}/></Col>
               </Row>
             </div>:this.state.number==3?
              <div>
              <Row  style={{ marginLeft:width*0.15, height: '30%', width:width*0.7}}>
                <Col span={8}><Schedule2 height={height*0.36} color='#00d2ff' totalPeople={17} radius='80%' peoplenum={this.state.peoplenum1}/></Col>
                <Col span={8}><Schedule2 height={height*0.36} color='#96ff00' totalPeople={17} radius='80%' peoplenum={this.state.peoplenum2}/></Col>
                <Col span={8}><Schedule2 height={height*0.36} color='#9e31c2' totalPeople={17} radius='80%' peoplenum={this.state.peoplenum3}/></Col>
              </Row>
            </div>:<div>
            <Row style={{ marginLeft:width*0.27, height: '30%', width:width*0.46}}>
              <Col span={12}><Schedule2 height={height*0.36} color='#ff2993' totalPeople={25} radius='80%' peoplenum={this.state.peoplenum1}/></Col>
              <Col span={12}><Schedule2 height={height*0.36} color='#ffb400' totalPeople={25} radius='80%' peoplenum={this.state.peoplenum2}/></Col>
            </Row>

          </div>
          }
               <img style={{width: width*0.24, height: 'auto',  }} src={require('../img/开始答题.png')} onClick={this.onClick6}>
               </img>
               <div style={{height: height*0.05,backgroundcolor:'#fffdf8'}} ></div>
               </div>:null}


               {this.state.PageFlag == "4" ? <div style={{opacity: this.state.opacity,}}>
               <img style={{width: width*0.3, height: 'auto', position: 'absolute', top:'38%', left:width*0.35 }} src={require('../img/设置答题人数.png')}>
               </img>
               <img style={{width: width*0.07, height: 'auto', position: 'absolute', top:'55%', left:width*0.23 }} src={require('../img/向左.png')} onClick={this.OnNumReduce}>
               </img>
               <img style={{width: width*0.07, height: 'auto', position: 'absolute', top:'55%', left:width*0.7 }} src={require('../img/向右.png')} onClick={this.OnNumAdd}>
               </img>
               <h style={{position: 'absolute', top:'47%',  fontSize:100, left:width*0.435}} >
               {this.state.number}
               </h>
               <img style={{width: width*0.16, height: 'auto', position: 'absolute', top:'85%', left:width*0.42  }} src={require('../img/确定.png')} onClick={this.onClick5}>
               </img>
               <img style={{width: width*0.12, height: 'auto', position: 'absolute', top:'94%', left:width*0.44 }} src={require('../img/返回.png')} onClick={this.onClick3}>
               </img>
               </div>:null}
                {this.state.PageFlag == "-2"?
                <div style={{opacity: this.state.opacity, backgroundColor:'#fffdf8', height:height}}>
                <InfiniteScroll
                  itemHeight={300}
                 dataLength={this.state.data.length}
                 hasMore={this.state.data.length < 50}
                 scrollableTarget="scrollableDiv"
               >

                 <List style={{marginTop:'10%' ,height:height*0.8}}
                   split={false}
                   dataSource={this.state.data}
                   renderItem={item => (
                     <List.Item key={item.email} style={{height:145}}>
                        <img style={{width: width*0.84, height: 'auto', position: 'absolute', marginTop:'6%', left:width*0.08 }} src={require('../img/主题'+item.themeId+'.png')} onClick={()=>this.onSelectClick1(item.themeId)}/>
                        {this.state.themeId == item.themeId?
                          <img style={{width: width*0.04, height: 'auto', position: 'absolute', marginTop:'30%', left:width*0.85 }} src={require('../img/选择.png')} >
                          </img>:<img style={{width: width*0.04, height: 'auto', position: 'absolute', marginTop:'30%', left:width*0.85 }} src={require('../img/未选择.png')} >
                          </img>}

                     </List.Item>
                   )}
                 />
                  </InfiniteScroll>
                <img style={{width: width*0.2, height: 'auto', position: 'absolute',top:'92%', left:width*0.4  }} src={require('../img/确定.png')} onClick={this.onClick3}>
                </img>
                <img style={{width: width*0.12, height: 'auto', position: 'absolute', top:'92.5%', left:width*0.78 }} src={require('../img/返回.png')} onClick={this.onClick1}>
                </img>
                </div>:null}
                {this.state.PageFlag == "9" ?
                <div style={{  textAlign: 'center', alignItems:'center',opacity: this.state.opacity, backgroundColor:'#fffdf8', height:height}}>
                <img style={{width: width, height: 'auto' }} src={require('../img/党徽2.jpg')}>
                </img>
                <img style={{width: 0.1*width, marginTop:'5%', height: 'auto' }} src={require('../img/游戏进行中.png')}>
                </img>
                <Spin style={{marginTop: 50}} size="large"> </Spin>
                <img style={{width: 0.3*width, marginTop:'25%', height: 'auto' }} src={require('../img/' + this.state.closeImage)} onClick={this.setResult}>
                </img>
                </div>:null }
                {this.state.PageFlag == "6" ?
                <div style={{opacity: this.state.opacity,}}>
                <div style={{backgroundColor: '#edededff', height:height*0.2}}> </div>
                <img style={{width: width*0.4, height: 'auto', position: 'absolute', top:'6%', left:width*0.3 }} src={require('../img/' + this.state.groupImage)}>
                </img>
                <img style={{width: width*0.38, height: width*0.38, position: 'absolute', top:'6.6%', left:width*0.31 , borderRadius:width*0.19}} src={this.state.headimgurl}>
                </img>
                <div>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.21}} src={this.state.yes[0]=="yes" ? require('../img/已答正确.png'):this.state.yes[0]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.27}} src={this.state.yes[1]=="yes" ? require('../img/已答正确.png'):this.state.yes[1]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.33}} src={this.state.yes[2]=="yes" ? require('../img/已答正确.png'):this.state.yes[2]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.39}} src={this.state.yes[3]=="yes" ? require('../img/已答正确.png'):this.state.yes[3]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.45}} src={this.state.yes[4]=="yes" ? require('../img/已答正确.png'):this.state.yes[4]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.51}} src={this.state.yes[5]=="yes" ? require('../img/已答正确.png'):this.state.yes[5]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.57}} src={this.state.yes[6]=="yes" ? require('../img/已答正确.png'):this.state.yes[6]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.63}} src={this.state.yes[7]=="yes" ? require('../img/已答正确.png'):this.state.yes[7]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.69}} src={this.state.yes[8]=="yes" ? require('../img/已答正确.png'):this.state.yes[8]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'30%', left:width*0.75}} src={this.state.yes[9]=="yes" ? require('../img/已答正确.png'):this.state.yes[9]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                </div>
                <div style={{ marginTop:'22%', justify:'center', textAlign: 'center' , width:width }}>
                <h style={{  fontSize:50, color:'#c1aa96' }} >
                {this.state.fraction}
                </h>
                </div >
                <div style={{ marginTop:'1%', justify:'center', textAlign: 'center' , width:width }}>
                <h style={{  fontSize:15 }} >
                {"用时：" + this.formatSeconds(this.state.userTime)}
                </h>

                </div >
                <div style={{ marginTop:'4%'}}>
                <div style={{marginLeft:width*0.1, marginRight:width*0.1, width:width*0.8}}>
                <h style={{ fontSize:15,  width:width*0.72}} >
                {this.state.answerData[this.state.answerIndex]["describe"]}
                </h>
                </div>
                {this.state.answerData[this.state.answerIndex]["TitleURL"] == ""?null:<img style={{width: width*0.8, height: 'auto',  marginTop:'5%',marginLeft:width*0.1 }} src={this.state.answerData[this.state.answerIndex]["TitleURL"]}/>}
                <div>
                <Radio.Group style={{width: width*0.7,marginLeft:width*0.15, marginTop:'5%'}} onChange={this.onSelectClick2} value={this.state.answer}>
                 <Space direction="vertical" >
                  <Radio value={"A"} >{this.state.answerData[this.state.answerIndex]["optionA"]}</Radio>
                  <Radio value={"B"} style={{marginTop:'5%'}}>{this.state.answerData[this.state.answerIndex]["optionB"]}</Radio>
                  {this.state.answerData[this.state.answerIndex]["optionC"] == ""? null:  <Radio style={{marginTop:'5%'}} value={"C"}>{this.state.answerData[this.state.answerIndex]["optionC"]}</Radio>}
                  {this.state.answerData[this.state.answerIndex]["optionD"] == ""? null:  <Radio style={{marginTop:'5%'}} value={"D"}>{this.state.answerData[this.state.answerIndex]["optionD"]}</Radio>}
                  </Space>
                </Radio.Group>
                </div>
                </div>
                <img style={{width: width*0.2, height: 'auto', marginTop:'10%', marginLeft:width*0.4 }} src={require('../img/答题确定.png')} onClick={this.onClick7}>
                </img>

                <Modal title={null} visible={this.state.modalVisible} okButtonProps={{disabled:true}} closable={false} footer={null} width='85%' centered={true}  style={{height: 300,  textAlign: 'center', alignItems:'center', borderRadius:50}} bodyStyle={{ borderRadius:50}} >
                <Spin style={{marginTop: 20}} size="large"> </Spin>
                <p style={{marginTop: 50}}> {this.state.modalMseeage}</p>
                </Modal>


                </div>:null}
                {this.state.PageFlag == "7"   || this.state.PageFlag == "8"  ||this.state.PageFlag == "10" ?
                <div style={{opacity: this.state.opacity,}}>
                <div style={{backgroundColor: '#edededff', height:height*0.27}}> </div>
                <img style={{width: width*0.5, height: 'auto', position: 'absolute', top:'7%', left:width*0.25 }} src={require('../img/' + this.state.groupImage )}>
                </img>
                <img style={{width: width*0.48, height: width*0.48, position: 'absolute', top:'7.55%', left:width*0.26 , borderRadius:width*0.24}} src={this.state.headimgurl}>
                </img>
                </div>:null}
                {this.state.PageFlag == "7"  ?
                <div style={{opacity: this.state.opacity,}}>
                <img style={{width: width*0.3, height: 'auto', position: 'absolute', top:'37%', left:width*0.35 }} src={require('../img/完成.png')}>
                </img>
                </div>:null}

                {this.state.PageFlag == "8" ?
                <div style={{opacity: this.state.opacity,}}>
                <img style={{width: width*0.84, height: 'auto', position: 'absolute', top:'37%', left:width*0.08 }} src={require('../img/第' + this.state.rank + '名.png')}>
                </img>
                <img style={{width: width*0.12, height: 'auto', position: 'absolute', top:'86%', left:width*0.44 }} src={require('../img/得分.png')}>
                </img>
                <div>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.2}} src={this.state.yes[0]=="yes" ? require('../img/已答正确.png'):this.state.yes[0]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.26}} src={this.state.yes[1]=="yes" ? require('../img/已答正确.png'):this.state.yes[1]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.32}} src={this.state.yes[2]=="yes" ? require('../img/已答正确.png'):this.state.yes[2]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.38}} src={this.state.yes[3]=="yes" ? require('../img/已答正确.png'):this.state.yes[3]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.44}} src={this.state.yes[4]=="yes" ? require('../img/已答正确.png'):this.state.yes[4]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.5}} src={this.state.yes[5]=="yes" ? require('../img/已答正确.png'):this.state.yes[5]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.56}} src={this.state.yes[6]=="yes" ? require('../img/已答正确.png'):this.state.yes[6]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.62}} src={this.state.yes[7]=="yes" ? require('../img/已答正确.png'):this.state.yes[7]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.68}} src={this.state.yes[8]=="yes" ? require('../img/已答正确.png'):this.state.yes[8]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                  <img style={{width: width*0.04, height: width*0.04, position: 'absolute', top:'90%', left:width*0.74}} src={this.state.yes[9]=="yes" ? require('../img/已答正确.png'):this.state.yes[9]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/>
                </div>
                </div>
                :null}

                {this.state.PageFlag == "10" ?
                <div style={{ opacity: this.state.opacity,}}>
                <img style={{width: width*0.3, height: 'auto', marginTop:'14%', marginLeft:width*0.35 }} src={require('../img/获得金句.png')}>
                </img>

                <div style={{marginTop:'5%',marginLeft:width*0.08, marginRight:width*0.08}}>
                <h style={{fontSize:12,  lineHeight:1.5 }}>
                 “向中华民族伟大复兴目标进发的巨轮,没有坐享其成的乘客、事不关己的看客,你,我,他,亿万万中国人,都是划桨者、搏击者。我们深知探明路子不易,深怀“四个自信”不移,喊着同一个号子,朝着同一个方向。“只要精诚团结、共同奋斗,就没有任何力量能够阻挡中国人民实现梦想的步伐!”
                </h>
                <div style={{marginTop:'2%',textAlign:'center'}}>
                <h style={{  fontSize:12 }}>
                 ——2021年3月1日，习近平总书记在中央党校（国家行政学院）中青年干部培训班开班式上的讲话
                </h>
                </div>

                </div>
                <img style={{width: width*0.2, height: 'auto',   marginLeft:width*0.4, }} src={require('../img/祥云.png')} >
                </img>
                <img style={{width: width*0.1, height: 'auto', marginTop:'3%', marginLeft:width*0.45, }} src={require('../img/分享.png')}>
                </img>
                </div>
                :null}


             </div>
            )
             }

  }


export default PageSZ2;
