import React , { useState, useEffect }  from 'react';
import REQUEST from '../util/request';
import API from '../util/api';
import ZJPicturesWall from './ZJPicturesWall'

import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Drawer,
  Col,
  Row,
  InputNumber

} from 'antd';

const { TextArea } = Input;
const originData = [];
const optionA = [];
const { Option } = Select;


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = title === '类型' ? null : <TextArea autoSize={{ minRows: 1, maxRows: 5 }}/>;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [loadData, setLoadData] = useState(true);
  const [infoId, setInfoId] = useState('');
  const [infoName, setInfoName] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [addYear, setAddYear] = useState('');
  const [addDate, setAddDate] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [showDetailFlag, setShowDetailFlag] = useState(false);
  const [titleURL, setTitleURL] = useState([]);

  const isEditing = record => record.key === editingKey;

  const edit = record => {

    form.setFieldsValue({
      infoName:'',
      infoId: '',
      introduction: '',
      addYear:'',
      addDate:'',

      ...record,
    });
    setEditingKey(record.key);
  };

  const onClose = () => {
      setDrawerVisible(false);
      onSelectClick();
    };


  //显示题目详情列表
  const showDetail = record => {

    setInfoId(record.key);
    onSelectPng(record.key);
    setInfoName(record.infoName);
    setAddYear(record.addYear);
    setAddDate(record.addDate);
    setIntroduction(record.introduction);


  };

  const cancel = (key) => {

    setEditingKey('');

  };

  const onAddClick=()=>{
    setDrawerVisible(true);
    setInfoName("");
    setAddYear("");
    setAddDate("");
    setIntroduction("");
    setInfoId("");
    setTitleURL([]);
  }

  const onSelectPng=(selctInfoId)=>{
    const sendData = {infoId:selctInfoId,picType:"02" }
    REQUEST.get(API.ZJSELECTMARKPOINTPNG, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      const newData = [];
      if (errorCode == "0000"){
        console.log(responseData);
        let resultCount = Number(responseData[0].resultCount);
            for (let i = 0; i < resultCount; i++) {
              newData.push({
                uid: responseData[i].imageId,
                name: "",
                status: responseData[i].status,
                url: responseData[i].address,

              });
            }
          setTitleURL(newData);
          setDrawerVisible(true);
          setShowDetailFlag(true);
        }
    });
}

  //查询题目
  const onSelectClick=()=>{
      const sendData = {}
      REQUEST.get(API.ZJSELECTACTIVITYINFO, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode == "0000"){
          setEditingKey('');
          let resultCount = Number(responseData[0].resultCount);
              for (let i = 0; i < resultCount; i++) {
                newData.push({
                  key: responseData[i].infoId,
                  infoName: responseData[i].infoName,
                  introduction:responseData[i].introduction,
                  addYear:responseData[i].addYear,
                  addDate:responseData[i].addDate,

                });
              }
            setData(newData);

          }
      });
  }

  const doLoadData=()=>{

    if (loadData != props.loadData){

      setLoadData(props.loadData);
      onSelectClick();
    }
  }
  doLoadData();

  const info = msg => {
  Modal.info({
    title: msg,

    onOk() {},
    });
  }

  //添加题目
  const addBasicsInfo=()=>{

    if (infoName == ""){
      info("请填写非遗名称");
      return;
    }


    if (addYear == ""){
      info("请填写年度！");
      return;
    }
    if (addDate == ""){
      info("请填写日期！");
      return;
    }


    if (introduction == ""){
      info("请填写简介！");
      return;
    }

    const sendData = {
      infoId:infoId,
      infoName:infoName,
      introduction:introduction,
      addYear:addYear,
      addDate:addDate,

  }
  if (infoId == ""){
    REQUEST.get(API.ZJADDACTIVITYINFO, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      if (errorCode == "0000"){

        let newInfoId = responseData[0].infoId;

        for (let i = 0; i < titleURL.length; i++) {
          const sendData2 = {
            infoId: newInfoId,
            address:titleURL[i]["url"],
            picType:"02",
          }
          REQUEST.get(API.ZJADDMARKPOINTPNG, sendData2, (responseData)=>{
            let errorCode2 = responseData[0].errorCode;
            if (errorCode2 == "0000"){}

        })}

        info(responseData[0].errorMsg);
        setInfoName("");
        setAddYear("");
        setAddDate("");
        setIntroduction("");
        setTitleURL([]);
        setDrawerVisible(false);
        onSelectClick();
            }
    });
  }
  else{
    REQUEST.get(API.ZJUPDATEACTIVITYINFO, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      if (errorCode == "0000"){
        info(responseData[0].errorMsg)}});
        setInfoName("");
        setAddYear("");
        setAddDate("");
        setIntroduction("");
        setTitleURL([]);
        setDrawerVisible(false);
        onSelectClick();

  }

  }



  const handleDelete = key => {

    Modal.confirm({
      title: "确认删除该明细",
      okText: '确认',
      cancelText: '取消',
      onOk() {
      const sendData = {
        infoId: key.toString(),
      }
      REQUEST.get(API.ZJDELETEACTIVITYINFO, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode == "0000"){
          info(responseData[0].errorMsg);
          onSelectClick();
      }});}})


  };

  const columns = [

    {
      title: '名称',
      dataIndex: 'infoName',
      key: 'infoName',
      width: '20%',
      editable: true,
    },

    {
      title: '年度',
      dataIndex: 'addYear',
      key: 'addYear',
      width: '10%',
      editable: true,
    },
    {
      title: '日期',
      dataIndex: 'addDate',
      key: 'addDate',
      width: '10%',
      editable: true,
    },

    {
      title: '简介',
      dataIndex: 'introduction',
      key: 'introduction',
      width: '50%',
      editable: true,
    },
    {
    title: '操作',
    dataIndex: 'operation',
    width: '10%',
    render: (_, record) => {

      return(
          <Space size="small">
            <a disabled={editingKey !== ''} onClick={() => showDetail(record)}>
              详情
            </a>
            <a onClick={() => handleDelete(record.key)}>
              删除
            </a>
          </Space>
        );

    }},
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false} labelCol={{ span: 1 }} wrapperCol={{ span: 22 }}>
    <Form.Item label="">
      <Space>
      <Button style={{  marginLeft:20}} type="primary" onClick={() =>onSelectClick()}>
        查询
      </Button>
      <Button style={{  marginLeft:20}} type="primary" onClick={() =>onAddClick()}>
        新增
      </Button>
      </Space>
    </Form.Item>
    <Form.Item label="查询结果">
      <Table
          components={{
          body: {
            cell: EditableCell,
          },
        }}
        width={1500}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
      </Form.Item>

      <Drawer
      title="详细信息"
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={drawerVisible}
      height='80%'
      size='large'
      key="right"
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={addBasicsInfo} type="primary">
            完成
          </Button>
        </Space>
      }
    >
      <Form form={form} component={false} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
      <Form.Item label="名称" >
        <TextArea  value={infoName}  onChange={value=>setInfoName(value.target.value)}  />
      </Form.Item>

      <Form.Item label="年度" >
        <TextArea  value={addYear}  onChange={value=>setAddYear(value.target.value)}  />
      </Form.Item>
      <Form.Item label="日期" >
        <TextArea  value={addDate}  onChange={value=>setAddDate(value.target.value)}  />
      </Form.Item>

      <Form.Item label="简介" style={{height:200}}>
      <TextArea  style={{height:200}} value={introduction}  onChange={value=>setIntroduction(value.target.value)}  />
      </Form.Item>
      {drawerVisible && infoName != ""?
        <Form.Item wrapperCol={{ span: 24, offset: 1}}>
         <ZJPicturesWall buttonName="图片上传" uploadType='image/*' maxFileCount='8' ImageType='02' themeId="活动" infoName={infoName} infoId={infoId} url={titleURL} handleGiftAid={(value)=>setTitleURL(value)}/>
        </Form.Item>
        : null}

    </Form>

        </Drawer>
      </Form>

  );
}


class PageZhanJiang3 extends React.Component{

  constructor(){
    super();
    this.state = {
        drawerVisible: false,
        loadData:false,
  	};
  }


  componentWillMount(){

  }

  getUrlParams =(name, str) => {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = str.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return "";
    }

render(){
  return (
    <div className="clearfix" style={{  marginTop:30}}>
    <EditableTable loadData={this.state.loadData} />
    </div>
  );
}

}


export default PageZhanJiang3;
