import React , { useState,useEffect }  from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined,WindowsFilled, AndroidFilled,AppleFilled} from '@ant-design/icons';
import VideoPlay from './VideoPlay';
import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Card, Avatar,
  List,
  Menu,
  Layout,
  Anchor,
  InputNumber,
  Row, Col ,Cascader
} from 'antd';

import Position from './Position';
import '../App.less';
import REQUEST from '../util/request';
import API from '../util/api';

const { Link } = Anchor;
const { Title } = Typography;
const { Meta } = Card;
const { Header, Content, Footer ,Sider} = Layout;

const data1 = [
  {
    title: 'Title 1',
  },
  {
    title: 'Title 2',
  },
  {
    title: 'Title 3',
  },
  {
    title: 'Title 4',
  },
  {
    title: 'Title 5',
  },
  {
    title: 'Title 6',
  },
  {
    title: 'Title 7',
  },
  {
    title: 'Title 8',
  },
  {
    title: 'Title 9',
  },
  {
    title: 'Title 10',
  },
  {
    title: 'Title 11',
  },
  {
    title: 'Title 12',
  },
];
const { TextArea } = Input;

const Page1 = (props) => {

  const [playFlag, setPlayFlag] = useState(false);
  const [isModalVisible, setModalVisible]= useState(false);
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');

  const [form] = Form.useForm();

  const handleOk = ()=>{

    console.log(form.getFieldsValue());
    const sendData = form.getFieldsValue();

    if(form.getFieldsValue()["name"] == ""){
      info("请输入您的姓名");
      return;
    }
    if(form.getFieldsValue()["phone"] == ""){
      info("请输入您的手机号码");
      return;
    }
    if (region == ""){
      info("请输入您所属省市");
      return;
    }
    if(form.getFieldsValue()["reamrk"] == ""){
      info("请输入您的需求");
      return;
    }
    sendData["province"] = province;
    sendData["city"] = city;
    sendData["region"] = region;

    REQUEST.get(API.ADDAPPLYINFO, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      console.log(responseData[0]);

      // const fileList = [];
      if (errorCode == "0000"){
        info("需求登记成功，我们将稍后与您联系！");
      }

      form.resetFields();
      setModalVisible(false);
  });



  }

  const handleCancel = ()=>{
    form.resetFields();
    setModalVisible(false);
    Modal.destroyAll();
  }
  const info = msg => {
  Modal.info({
  title: msg,

  onOk() {},
  });
}

  const buttonClick = ()=>{
    setModalVisible(true);
    // Modal.destroyAll();
  }

  const onChange = (value)=>{

    if (value.length>0){
      setProvince(value[0]);
    }
    if (value.length>1){
      setCity(value[1]);
    }
    if (value.length>2){
      setRegion(value[2]);
    }


    // Modal.destroyAll();
  }

  // <Header style={{ position: 'fixed', zIndex: 1, width: '100%' ,width:props.w,backgroundColor:'#dcdee0',height:200}}>
  // <div style={{ width:props.w,height:200}}>
  // <div style={{marginLeft:props.w*0.05,  position: 'fixed', width:props.w,height:200,zIndex: 1}}>
  // <Row align="middle" justify="start" >
  // <Col span={16}>
  // <Title style={{marginTop:25}}>中云加,  科技改变工作方式</Title>
  // <Title level={4} strong={false}>专注科技化营销展示及管理应用系统</Title>
  // <Title level={4} style={{marginBottom:100}}>为客户提供线上线下科技化营销展示管理等应用服务</Title>
  // </Col>
  // <Col span={8}>
  //  <img src={require('../img/logo2.png')} style={{width:'auto',height:100, }}/>
  //  </Col>
  //  </Row>
  // </div>
  // </div>
  // </Header>

  return (
    <div style={{ marginTop:60 , }}>

    <Layout className="site-layout-background" style={{ height:props.isLandscape?props.h-60:props.h-60}}>


  <Content >

  {props.isLandscape?

<Row style={{ height:props.h*0.2, marginLeft:props.w*0.05+50, marginTop:props.h*0.1}} align="middle" justify="start" >
  <Col span={16} >
  <p style={{fontSize:50, fontWeight:1, marginTop:5, marginBottom:0}} >中云加城市合伙人</p>
  <p style={{fontSize:50, fontWeight:1, marginTop:0, marginBottom:20}} >火热招募中</p>

  <Button type="primary" size="large" onClick={buttonClick}>立即申请</Button>
</Col>
</Row>:
<Row style={{ height:props.h*0.2, marginLeft:30, marginTop:props.h*0.1}} align="middle" justify="start" >
  <Col span={24} >
  <p style={{fontSize:25, fontWeight:1, marginTop:5, marginBottom:0}} >中云加城市合伙人火热招募中</p>

  <Button type="primary" size="large" onClick={buttonClick}>立即申请</Button>
</Col>
</Row>

}
{props.isLandscape?
<Row style={{ height:props.h*0.5, marginLeft:props.w*0.05+50}} align="middle" justify="start" >
  <Col span={16} >
  <p style={{fontSize:40, fontWeight:10, marginTop:100, marginBottom:0}} >联系方式</p>
  <p style={{fontSize:30, fontWeight:1, marginTop:20, marginBottom:20}} >地址:  广州市番禺区南桂路7号肇源商业城2号楼302                 联系电话:  陈先生 13570228489</p>


</Col>
</Row>:
<Row style={{ height:props.h*0.5, marginLeft:30}} align="middle" justify="start" >
  <Col span={24} >
  <p style={{fontSize:20, fontWeight:10, marginTop:5, marginBottom:0}} >联系方式</p>
  <p style={{fontSize:15, fontWeight:1, marginTop:20, marginBottom:5}} >地址:  广州市番禺区南桂路7号肇源商业城2号楼302</p>
  <p style={{fontSize:15, fontWeight:1, marginTop:0, marginBottom:20}} >联系电话:  陈先生 13570228489</p>


</Col>
</Row>}


<Modal okText="确认" cancelText="取消" title="请填写您的信息" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form  form={form} layout="vertical" hideRequiredMark >
          <Row gutter={16}>
            <Col span={24}>
            <Form.Item name="name"
              rules={[{ required: true }]}
            >
            <TextArea placeholder="姓名"/>
            </Form.Item>
            <Form.Item name="phone"

              rules={[{ required: true, message: '请输入手机号码!' }]}
        >
          <Input    placeholder="手机号码" style={{ width: '100%' }} />

            </Form.Item>
<Form.Item>
            <Cascader
  options={Position}
  placeholder="请选择您的城市"
  onChange={onChange}
/>
  </Form.Item>
            <Form.Item name="reamrk"
              rules={[{ required: true }]}
            >
            <TextArea  autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请描述您的需求，我们尽快联系您（200字以内）"/>
            </Form.Item>

            </Col>
          </Row>

        </Form>
      </Modal>
            </Content>
      </Layout>

 </div>



  );
}

export default Page1;
