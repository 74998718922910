import React , { useState,useEffect }  from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined,WindowsFilled, AndroidFilled,AppleFilled} from '@ant-design/icons';
import VideoPlay from './VideoPlay';
import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Card, Avatar,
  List,
  Menu,
  Layout,
  Anchor,
  Row, Col ,Cascader
} from 'antd';

import Position from './Position';
import '../App.less';
const { Link } = Anchor;
const { Title } = Typography;
const { Meta } = Card;
const { Header, Content, Footer ,Sider} = Layout;


const { TextArea } = Input;

const Page2 = (props) => {

  const [playFlag, setPlayFlag] = useState(false);
  const [isModalVisible, setModalVisible]= useState(false);

  const handleOk = ()=>{
    setModalVisible(false);
  }

  const handleCancel = ()=>{
    setModalVisible(false);
    Modal.destroyAll();
  }

  const buttonClick = ()=>{
    setModalVisible(true);
    // Modal.destroyAll();
  }

  const onChange = ()=>{

    // Modal.destroyAll();
  }

  return (
    <div style={{ marginTop:60 ,  }}>

    <Layout className="site-layout-background" style={{ height:props.h-150}}>

    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' ,width:props.w,backgroundColor:'#dcdee0',height:200}}>
    <div style={{ width:props.w,height:200}}>
    <div style={{marginLeft:props.w*0.05,  position: 'fixed', width:props.w,height:200,zIndex: 1}}>
    <Row align="middle" justify="start" >
    <Col span={16}>
    <Title style={{marginTop:25}}>中云加,  科技改变工作方式</Title>
    <Title level={4} strong={false}>专注科技化营销展示及管理应用系统</Title>
    <Title level={4} style={{marginBottom:100}}>为客户提供线上线下科技化营销展示管理等应用服务</Title>
    </Col>
    <Col span={8}>
     <img src={require('../img/logo2.png')} style={{width:'auto',height:100, }}/>
     </Col>
     </Row>
    </div>
    </div>
    </Header>
  <Content style={{ marginTop:200  }}>

<Row style={{ height:props.h*0.5, marginLeft:props.w*0.05+50}} align="middle" justify="start" >
  <Col span={16} >
  <p style={{fontSize:40, fontWeight:10, marginTop:5, marginBottom:0}} >联系方式</p>
  <p style={{fontSize:30, fontWeight:1, marginTop:20, marginBottom:20}} >地址:  广州市番禺区南桂路7号肇源商业城2号楼302                 联系电话:  陈先生 13570228489</p>


</Col>
</Row>

            </Content>
      </Layout>

 </div>



  );
}

export default Page2;
