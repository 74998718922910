import React, { Component, Fragment } from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined,WindowsFilled, AndroidFilled,AppleFilled} from '@ant-design/icons';
import {
  Player,
  ControlBar,
  PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
  ReplayControl, // 后退按钮
  ForwardControl,  // 前进按钮
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,  // 倍速播放选项
  VolumeMenuButton,



} from 'video-react';
import { Row, Col ,Typography,Space,  Popconfirm,Popover,
  Button,Layout,
  Collapse} from 'antd';
import "../../node_modules/video-react/dist/video-react.css"; // import css

const { Title,Text } = Typography;
const { Panel } = Collapse;
const { Header, Content, Footer ,Sider} = Layout;


const Content1 = (props)=>{
  return (<div>
    <img alt="example" src={props.dealUrl} style={{width:150, height:150}} />
  </div>
);
}

class VideoPlayH5 extends Component {
  componentDidMount() {
    console.log(this.player)
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    this.player.load();
    // this.player.play();
  }

  handleStateChange(state, prevState) {
    // console.log(state)
  }




  render() {


    return (
      <Fragment>
      <Row justify = 'center' >
     <div style={{ marginTop: 20, marginBottom:10}}>
       <Player
         style={{ }}
         ref={c => {
           this.player = c;
         }}
         autoPlay={true}
         fluid={false}
         width={this.props.width * 0.9}
         height={this.props.width * 0.9 /1.6}
         poster={this.props.dealItem.pngUrl}

       >
         <source
           src={this.props.dealItem.videoUrl}
           type="video/mp4"
         />
         <ControlBar autoHide={false} disableDefaultControls={false}>

           <PlayToggle />
           <CurrentTimeDisplay order={4.1} />
           <TimeDivider order={4.2} />
           <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
           <VolumeMenuButton />
         </ControlBar>
       </Player>
       </div>
       </Row>

     <Layout style={{ height: '98%', backgroundColor:'white' }}>
     <Content style={{ height: '80%', backgroundColor:'white' }}>
     <div style={{marginLeft:15}}>
     <Title level={4} style={{marginTop:0}}>{this.props.dealItem.productName}</Title>
      {this.props.dealItem.reamrk != ""?
     <Title style={{marginRight:10,}} level={5} >{"适合场所：" + this.props.dealItem.useScenario}</Title>:null}
     <Title style={{marginRight:10,}} level={5} >{ this.props.dealItem.explain}</Title>

     <Row style={{marginTop:30}}>
      {this.props.dealItem.winUrl !=null && this.props.dealItem.winUrl!=""?
     <div style={{}} >
      <img src={require('../img/win.png')} style={{width:'auto',height:60,}}/>
      <div style={{textAlign:'left'}} >
      <Title level={5}  style={{marginTop:10, textAlign:'left'}}>Windows编辑端
      </Title>
      <Title level={5}  underline style={{ textAlign:'left'}}>
      <a href={this.props.dealItem.winUrl} target="_blank">点击下载</a></Title>
      </div>
     </div>
  :null}
      {this.props.dealItem.iosUrl !=null && this.props.dealItem.iosUrl!=""?
      <Col span={7}>
      <div >
       <img src={require('../img/ios.png')} style={{width:'auto',height:60}}/>
       <div style={{textAlign:'left'}} >
       <Title level={5}  style={{marginTop:10, textAlign:'left'}}>IOS使用端
       </Title>
         <Popover placement="topLeft" title="扫码下载" content=<Content1 dealUrl={this.props.dealItem.iosUrl}/> trigger="click">
       <Title level={5}  underline style={{ textAlign:'left'}}>
       <a>点击扫码下载</a></Title>
       </Popover>
       </div>
      </div>
       </Col>:null}
         {this.props.dealItem.androidUrl !=null && this.props.dealItem.androidUrl!=""?
       <Col span={7}>
       <div style={{}} >
        <img src={require('../img/android.png')} style={{width:'auto',height:60,}}/>
        <div style={{ textAlign:'left'}} >
        <Title level={5}  style={{marginTop:10, textAlign:'left'}}>安卓使用端</Title>

        <Popover placement="topLeft" title="扫码下载" content=<Content1 dealUrl={this.props.dealItem.androidUrl}/> trigger="click">
      <Title level={5}  underline style={{ textAlign:'left'}}>
      <a>点击扫码下载</a></Title>
      </Popover>
        </div>
       </div>
        </Col>:null}

     </Row>
     </div>
     </Content>
     <Footer style={{ backgroundColor:'white' }}>

     {this.props.dealItem.reamrk != ""? <Title style={{fontSize:10, fontWeight:1 }} level={5} ><p >{"备注："+ this.props.dealItem.reamrk}</p></Title>:null}

   </Footer>

     </Layout>





      </Fragment>
    )
  }
}


export default VideoPlayH5;
