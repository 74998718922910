import '../App.less';
import style from '../App.css';
import React , { useState, useEffect }  from 'react';
import REQUEST from '../util/request';
import API from '../util/api';
import ReactWebsocket from './ReactWebsocket'
import { Carousel,Spin,Modal,Rate,Row ,Col ,Space,Typography,List,Button,Form,Radio,Card,Layout ,Image  } from 'antd';
import URI from 'urijs';
import wx from 'weixin-js-sdk';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactEcharts from 'echarts-for-react';
import 'echarts-liquidfill/src/liquidFill.js'
import bj1 from '../img/zhanjiang/bj1.jpg';
import bj2 from '../img/zhanjiang/bj2.jpg';
import { EditOutlined, EllipsisOutlined, SettingOutlined,PauseCircleOutlined,PlayCircleOutlined ,BarsOutlined,CloseCircleFilled,CloseOutlined} from '@ant-design/icons';

const w = document.documentElement.clientWidth;
const h =  document.documentElement.clientHeight;
const isLandscape = (w > h);
const width = isLandscape ? h: w;
const height = isLandscape ? w: h;


const { Title } = Typography;
const { confirm } = Modal;
const { Header, Sider, Content } = Layout;

const ShowData = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(props.dataSource);
  const [themeId, setThemeId] = useState(props.themeId);
  const [urlList, setUrlList] = useState([]);
  const [infoId, setInfoId] = useState('');
  const [infoName, setInfoName] = useState('');
  const [category, setCategory] = useState('');
  const [area, setArea] = useState('');
  const [unit, setUnit] = useState('');
  const [level, setLevel] = useState('');
  const [range, setRange] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [likeCount, setLikeCount] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [likeFlag, setLikeFlag] = useState(false);
  const [likeList, setLikeList] = useState([]);

  const onLikeClick=()=>{
    if (likeList.indexOf(infoId) < 0){
      const likeList1 = likeList;
      likeList1.push(infoId);


      const sendData = {themeId: themeId,infoId:infoId }
      REQUEST.get(API.ZJADDLIKE, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        if (errorCode == "0000"){
          setLikeFlag(true);
          setLikeList(likeList1);
          }
      });

    }
  }

  const onDetailClick = (infoitem) => {


    setInfoId(infoitem.key);
    setInfoName(infoitem.infoName);
    setCategory(infoitem.category);
    setArea(infoitem.area);
    setUnit(infoitem.unit);
    setLevel(infoitem.level);
    setRange(infoitem.range);
    setIntroduction(infoitem.introduction);
    onSelectPng(infoitem.key);

    if (likeList.indexOf(infoitem.key) >= 0){
      setLikeFlag(true);
    }
    else{
      setLikeFlag(false);
    }

    };


    const onSelectPng=(selctInfoId)=>{
      const sendData = {themeId: themeId,infoId:selctInfoId,picType:"01" }
      REQUEST.get(API.ZJSELECTMARKPOINTPNG, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode == "0000"){
          console.log(responseData);
          let resultCount = Number(responseData[0].resultCount);
              for (let i = 0; i < resultCount; i++) {
                newData.push({
                  uid: responseData[i].imageId,
                  url: responseData[i].address,

                });
              }
            setUrlList(newData);
            setShowDetail(true);
          }
      });
  }

  const closeClick=()=>{
    const sendData = {themeId: themeId, orderby:'likeCount' };
    REQUEST.get(API.ZJSELECTMSGBASE, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      let index = 1;
      const newData = [];
      if (errorCode == "0000"){

        let resultCount = Number(responseData[0].resultCount);
            for (let i = resultCount-1; i >=0; i--) {
              newData.push({
                index:index,
                key: responseData[i].infoId,
                themeId: responseData[i].themeId,
                themeName: responseData[i].themeName,
                infoName: responseData[i].infoName,
                category: responseData[i].category,
                area : responseData[i].area,
                unit : responseData[i].unit,
                level: responseData[i].level,
                range: responseData[i].range,
                introduction:responseData[i].introduction,
                likeCount:responseData[i].likeCount,

              });
              index++;
            }
            setData(newData);
            setShowDetail(false);
            }
        });


  };

  return (<div style={{height: height*0.85 }}>

      <img style={{width: width*0.4, height: 'auto', marginTop:height*0.05, marginLeft:width*0.3}} src={require('../img/zhanjiang/'+ themeId + '.png')} >
      </img>
    <div style={{width: width*0.9, height: height*0.7, marginTop:height*0.025, marginLeft:width*0.05,backgroundSize: '100% 100%', backgroundImage:`url(${require('../img/zhanjiang/框.png')})`}}>
    <List style={{height:height*0.62,top:height*0.04, textAlign:'center',overflow: 'auto'}}
      split={false}
      dataSource={data}
      renderItem={item => (
        <List.Item key={item.key} style={{height: width*0.78 *0.11}} onClick={()=>onDetailClick(item)} >
           <img style={{width: width*0.78, height: 'auto' ,marginLeft:width*0.06}} src={require('../img/zhanjiang/圆角矩形.png')} />
           <Row style={{position:'absolute', width: width*0.65,marginTop:15 ,marginLeft:width*0.1}} justify="center">
           <div style={{width: width*0.06 ,color:'#bf8b5c'}}>{item.index}</div>
           <div class="text-truncate" style={{width: width*0.5 ,color:'#bf8b5c',overflow: 'hidden'}}>{item.infoName}</div>
           <div style={{width: width*0.09,color:'#bf8b5c' }}>{item.likeCount}</div>
           </Row>
        </List.Item>
      )}
    />
    </div>
    <div> {showDetail?
      <Modal  style={{top:height*0.09, left:-width*0.02}} wrapClassName={'web'} mask={false}   visible  footer={null}  onCancel={closeClick} closeIcon=<CloseCircleFilled style={{marginTop:15,fontSize:20 }}/> >
      <div style={{width: width, height: height*0.9,  backgroundSize: '100% 100%',textAlign:'center', justify:'center', backgroundImage:`url(${require('../img/zhanjiang/详情底.png')})`}}>
      <img style={{width: width*0.1, height: height*0.03 }} src={require('../img/zhanjiang/透明.png')} />
        <Title style={{fontSize:16, color:'#ae7a37'}} level={6}> {infoName} </Title>
        {urlList.length > 0? <Carousel  style={{marginTop:20, height:width*0.91/1.55, width:width*0.91,marginLeft:width*0.045 ,textAlign:'center', justify:'center'}} dotPosition = 'bottom' autoplay  effect="fade" >
        {urlList.map((item,index)=>{
          return (
                  <div style={{width: width*0.91, height: width*0.91/1.5,  backgroundSize: '100% 100%',textAlign:'center', justify:'center', backgroundImage:`url(${require('../img/zhanjiang/透明.png')})`}}>
                  <Image
                    height={width*0.91/1.5}
                    src={item.url}
                  />
                  </div>
                )
                    })
                }
        </Carousel>:null}


    <Content style={{height:height*0.73-(width*0.91/1.55), overflow: 'auto',marginTop:-20, width:width*0.95}}>
    <div style={{width: width*0.8, marginLeft:width*0.1, marginTop:height*0.05, textAlign:'center', justify:'center'}}>
    <Row  style={{fontSize:15,textAlign:'left',color:'#6d4e27'}}>
    <div> {'类　　别：'}</div>
    <div style={{marginLeft:10, width:width*0.55}}> {category}</div>
    </Row>
    {area==""?null:<Row  style={{fontSize:15, marginTop:10,textAlign:'left',color:'#6d4e27'}}>
    <div> {'申报地区：'}</div>
    <div style={{marginLeft:10, width:width*0.55}}> {area}</div>
    </Row>}

    <Row  style={{fontSize:15, marginTop:10,textAlign:'left',color:'#6d4e27'}}>
    <div> {'保护单位：'}</div>
    <div style={{marginLeft:10, width:width*0.55}}> {unit}</div>
    </Row>
    <Row  style={{fontSize:15, marginTop:10,textAlign:'left',color:'#6d4e27'}}>
    <div> {'级　　别：'}</div>
    <div style={{marginLeft:10, width:width*0.58}}> {level}</div>
    </Row>
    <Row  style={{fontSize:15, marginTop:10,textAlign:'left',color:'#6d4e27'}}>
    <div> {'范　　围：'}</div>
    <div style={{marginLeft:10, width:width*0.55}}> {range}</div>
    </Row>
    <img style={{width: width*0.1, height: 'auto' ,marginTop:20}} src={require('../img/zhanjiang/简介.png')} />
    <div style={{width:width*0.8,marginTop:20,fontSize:15,textAlign:'left',color:'#6d4e27',lineHeight:1.5}}> {'　　'+ introduction}</div>
    </div>
    </Content>

    <img style={{width: width*0.05, height: 'auto' ,marginTop:20,}} src={require(likeFlag? '../img/zhanjiang/爱心.png':'../img/zhanjiang/爱心2.png')} onClick={()=>onLikeClick()}/>


</div>
      </Modal>:null}</div>

    </div>)
}


class PageZhanJiang2 extends React.Component{
  constructor(){
    super();
    this.state = {
      nowIndex : 0,
      themeId: -1,
      data:[],
  	};
  }


  componentWillMount(){
      document.title='湛江非遗点赞';
  }

  getUrlParams =(name, str) => {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = str.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return "";
    }


entryClick=(index)=>{
  this.setState({nowIndex:index})
}

entryListClick=(index)=>{
  this.setState({themeId:index});
  const sendData = {themeId: index,orderby:'likeCount' }
  REQUEST.get(API.ZJSELECTMSGBASE, sendData, (responseData)=>{
    let errorCode = responseData[0].errorCode;
    const newData = [];
    if (errorCode == "0000"){

      let resultCount = Number(responseData[0].resultCount);
      let index = 1;
          for (let i = resultCount-1; i >= 0; i--) {
            newData.push({
              index:index,
              key: responseData[i].infoId,
              themeId: responseData[i].themeId,
              themeName: responseData[i].themeName,
              infoName: responseData[i].infoName,
              category: responseData[i].category,
              area : responseData[i].area,
              unit : responseData[i].unit,
              level: responseData[i].level,
              range: responseData[i].range,
              introduction:responseData[i].introduction,
              likeCount:responseData[i].likeCount,

            });
            index++;
          }
          this.setState({data:newData});
          console.log(newData);
          this.setState({nowIndex:3});
          }
      });

}

render(){
  return (
    <div>
    {this.state.nowIndex == 0 ?
    <div style={{backgroundImage: `url(${bj1})`,width: width, height: height,backgroundSize: 'cover'}}>
      <img style={{width: width*0.3, height: 'auto', marginTop:height*0.28, marginLeft:width*0.35 }} src={require('../img/zhanjiang/标题.png')}>
      </img>
      <img style={{width: width*0.15, height: 'auto',position: 'absolute',  top:height*0.36, left:width*0.62 }} src={require('../img/zhanjiang/说明.png')}>
      </img>
      <img style={{width: width*0.8, height: 'auto', marginTop:height*0.02, marginLeft:width*0.1}} src={require('../img/zhanjiang/点击进入.png')} onClick={ ()=> this.entryClick(1)} >
      </img>
    </div>:<div style={{backgroundImage: `url(${bj2})`,width: width, height: height,backgroundSize: 'cover'}}>
    { this.state.nowIndex == 1?
    <div>
    <Carousel dots='slick-dots' className='Carousel' dotPosition = 'bottom' autoplay >
      <div>
        <img src={require('../img/zhanjiang/图片1.png')} style={{height:'auto',width:width}}/>
      </div>
      <div>
        <img src={require('../img/zhanjiang/图片1.png')} style={{height:'auto',width:width}}/>
      </div>
      <div>
        <img src={require('../img/zhanjiang/图片1.png')} style={{height:'auto',width:width}}/>
      </div>
      <div>
        <img src={require('../img/zhanjiang/图片1.png')} style={{height:'auto',width:width}}/>
      </div>
    </Carousel>

    <div style={{width: width*0.92, height: width*0.92*1.4, marginTop:height*0.05, marginLeft:width*0.04,backgroundSize: 'cover', backgroundImage:`url(${require('../img/zhanjiang/级别分类.png')})`}} >
    <Row  justify="center">
    <img style={{width: width*0.16, height: 'auto', marginTop:width*0.92*1.4*0.4, }} src={require('../img/zhanjiang/国家级.png')} onClick={ ()=> this.entryListClick(1)}>
    </img>
    <img style={{width: width*0.16, height: 'auto', marginTop:width*0.92*1.4*0.4, marginLeft:20, marginRight:20}} src={require('../img/zhanjiang/省级分类.png')} onClick={ ()=> this.entryListClick(2)}>
    </img>
    <img style={{width: width*0.16, height: 'auto', marginTop:width*0.92*1.4*0.4, }} src={require('../img/zhanjiang/市级分类.png')} onClick={ ()=> this.entryListClick(3)}>
    </img>
    </Row>

    </div>


    <img style={{width: width*0.16, height: 'auto', marginTop:height*0.02, marginLeft:width*0.42}} src={require('../img/zhanjiang/返回.png')}  onClick= { ()=> this.entryClick(0)} >
    </img>
    </div>:<div><ShowData dataSource={this.state.data} themeId={this.state.themeId} />
    <img style={{width: width*0.16, height: 'auto', marginTop:height*0.02, marginLeft:width*0.42}} src={require('../img/zhanjiang/返回.png')}  onClick= { ()=> this.entryClick(1)} >
    </img>
    </div>}</div>
    }</div>
  );
}

}


export default PageZhanJiang2;
