var API_ADDRESS = 'http://39.105.202.145:8004';
// var API_ADDRESS='https://api.bqmart.cn';
var DEBUG = true;
//  public static final String SERVERURL = "https://api.bqmart.cn";
//	public static final String SERVERURL = "http://test.api.bqmart.cn";

var API = {
	getSmsCode:function (type) {
		return API_ADDRESS+'/sms/sendcode'
	},
		HOST: 			    API_ADDRESS,
		SELECTCLASS: API_ADDRESS + '/zhongyunjia/selectClassification/',
		UPDATECLASS: API_ADDRESS + '/zhongyunjia/updateClassification/',
		DELETECLASS: API_ADDRESS + '/zhongyunjia/deleteClassification/',
		ADDCLASS: API_ADDRESS + '/zhongyunjia/addClassification/',
		DOSELECTPRODUCT: API_ADDRESS + '/zhongyunjia/selectProduct/',
		DELETEPRODUCT:API_ADDRESS + '/zhongyunjia/deleteProduct/',
		UPDATEPRODUCT:API_ADDRESS + '/zhongyunjia/updateProduct/',
		ADDPRODUCT:API_ADDRESS + '/zhongyunjia/addProduct/',
		ADDMARKPOINTPNG: API_ADDRESS + '/zhongyunjia/addMarkpointPng/',
		DELTEMARKPOINTPNG: API_ADDRESS + '/zhongyunjia/deleteMarkpointPng/',
		GETMARKPOINTPNG: API_ADDRESS + '/zhongyunjia/getMarkpointPng/',
		ADDAPPLYINFO: API_ADDRESS + '/zhongyunjia/addApplyInfo/',


		ADDMARKPOINT: API_ADDRESS + '/zhongyunjia/addMarkpoint/',

    UPDATEPOSITION: API_ADDRESS + '/AudioPlay/updatePosition/',
    UPDATEURL: API_ADDRESS + '/AudioPlay/updateUrl/',

    DETETEMARKPOINT: API_ADDRESS + '/AudioPlay/deleteMarkpoint/',
    UPDATEMARKPOINT: API_ADDRESS + '/AudioPlay/updateMarkpoint/',

		SELECTBASICSINFO: API_ADDRESS + '/Answer/selectBasicsInfo/',
		ADDBASICSINFO: API_ADDRESS + '/Answer/addBasicsInfo/',
		DELETEBASICSINFO: API_ADDRESS + '/Answer/deleteBasicsInfo/',
		UPDATEBASICSINFO: API_ADDRESS + '/Answer/updateBasicsInfo/',
		SELECTQUESTIONINFO: API_ADDRESS + '/Answer/selectQuestionInfo/',
		UPDATEQUESTIONINFO: API_ADDRESS + '/Answer/updateQuestionInfo/',
		DELETEQUESTIONINFO: API_ADDRESS + '/Answer/deleteQuestionInfo/',
		ADDQUESTIONINFO: API_ADDRESS + '/Answer/addQuestionInfo/',
		DELTEPOSTSCRIPT:  API_ADDRESS + '/Answer/deletePostscript/',
		UPDATEPOSTSCRIPT:  API_ADDRESS + '/Answer/updatePostscript/',
		ADDHOST:  API_ADDRESS + '/Answer/addHost/',
		CHOOSEQUESTION:  API_ADDRESS + '/Answer/chooseQuestion/',
		CHOOSETHEMEID:  API_ADDRESS + '/Answer/chooseThemeId/',
		SETANSWERMODE: API_ADDRESS + '/Answer/setAnswerMode/',
		SETANSWERSTART: API_ADDRESS + '/Answer/setAnswerStart/', //设置开始答题
		SETANSWERKEY: API_ADDRESS + '/Answer/setAnswerKey/', //设置开始答题
		GETRESULT: API_ADDRESS + '/Answer/getResult/', //获取结果
		GETWXINFO: API_ADDRESS + '/Answer/getWxInfo/', //获取结果
		ADDANSWERPEOPLE: API_ADDRESS + '/Answer/addAnswerPeople/', //获取结果
		GETWXINFOPNG: API_ADDRESS + '/Answer/getWxInfo2/', //获取结果
		SETRESULT: API_ADDRESS + '/Answer/setReuslt/',
		ZJSELECTTHEME:API_ADDRESS + '/zhanjiang/selectTheme/', //查询主题信息
		ZJSELECTMSGBASE:API_ADDRESS + '/zhanjiang/selectMsgbase/', //查询非遗基础信息
		ZJADDBASEINFO:API_ADDRESS + '/zhanjiang/addBaseInfo/', //添加非遗基础信息
		ZJDELETEBASEINFO:API_ADDRESS + '/zhanjiang/deleteBaseInfo/', //删除非遗基础信息
		ZJADDMARKPOINTPNG: API_ADDRESS + '/zhanjiang/addMarkpointPng/',
		ZJSELECTMARKPOINTPNG: API_ADDRESS + '/zhanjiang/selectMarkpointPng/',
		ZJDELTEMARKPOINTPNG: API_ADDRESS + '/zhanjiang/deleteMarkpointPng/',
		ZJUPDATEBASEINFO:API_ADDRESS + '/zhanjiang/updateBaseInfo/', //更新非遗基础信息
		ZJADDLIKE:API_ADDRESS + '/zhanjiang/addLike/', //点赞
		ZJSELECTACTIVITYINFO:API_ADDRESS + '/zhanjiang/selectActivityInfo/', //查询非遗基础信息
		ZJADDACTIVITYINFO:API_ADDRESS + '/zhanjiang/addActivityInfo/', //添加非遗基础信息
		ZJDELETEACTIVITYINFO:API_ADDRESS + '/zhanjiang/deleteActivityInfo/', //删除非遗基础信息
		ZJUPDATEACTIVITYINFO:API_ADDRESS + '/zhanjiang/updateActivityInfo/', //更新非遗基础信息



	};

module.exports = API;
