import React , { useState,useEffect }  from 'react';
import {
  Form,
  Select,
  InputNumber,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Layout,
   Menu, Breadcrumb
} from 'antd';
import './App.less';
import Home from './pages/Home';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
const { Option } = Select;
const { Title } = Typography;
const { Header, Content, Footer ,Sider} = Layout;

const w = document.documentElement.clientWidth;
const h =  document.documentElement.clientHeight;
const isLandscape = (w > h);
const width = isLandscape ? h: w;
const height = isLandscape ? w: h;

const App = () => {

  document.title='中云加';

  const [selectFlag, setSelectFlag] = useState("mail");

  const handleClick= (e)=>{
    setSelectFlag(e.key);
  }
  return (
  <Layout className="layout"  >
  <Header style={{ position: 'fixed', zIndex: 1, width: '100%' ,height:isLandscape? 68:68}}>
    <div className="logo" />
    <Space>
    {isLandscape? <img src={require('./img/logo.png')} style={{height:45,width:'auto',marginRight:10}}/>:<img src={require('./img/logo.png')} style={{height:30,width:'auto'}}/>}
    {isLandscape?  <Title level={2} style={{marginLeft:25,marginTop:7,  width:w*0.4,color:'white'}}>中云加,  科技改变体验方式</Title>:null }

    <Menu onClick={handleClick} style={{marginLeft:isLandscape?w*0.1:20, width:isLandscape?w*0.4:w*0.6, }} theme="dark" mode="horizontal" defaultSelectedKeys={['mail']} >
                  <Menu.Item key="mail">
                  产品中心
                  </Menu.Item>
                  <Menu.Item key="app" >
                  商务合作
                  </Menu.Item>

                </Menu>
    </Space>
    </Header>

          <Content style={{backgroundColor:'#dcdee0',}}>
    {selectFlag=="mail"?
    <Home w={w} h={h} />:selectFlag=="app"? <Page1 w={w} h={h}  isLandscape={isLandscape} /> :  <Page2 w={w} h={h} isLandscape={isLandscape}/>}
    </Content>
    <a style={{height:40,  textAlign: 'center'}} href="https://beian.miit.gov.cn">粤ICP备2021074835号</a>

</Layout>
)
}

export default App;
