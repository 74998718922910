import axios from 'axios';

var REQUEST = {

    get: function (url, data, callback) {
    url = url+"?";

    var indexInt = 0;
    for(var key in data){

      if (indexInt == 0){
        url = url + key + "=" + data[key];
      }
      else{
        url = url + "&" + key + "=" + data[key];
      }
      indexInt ++;

    };


        axios.get(url)
        .then((response)=> {
            //用到this要注意this指向
            callback(response.data);
        })
        // .catch(function (error) {
        //     console.log(error);
        // })
        ;
    }
};

export default REQUEST;
