import '../App.less';
import React , { useState, useEffect }  from 'react';
import REQUEST from '../util/request';
import API from '../util/api';
import ReactWebsocket from './ReactWebsocket'
import { Carousel,Spin,Modal,Rate,Row ,Col ,Space,Typography,List,Button,Radio   } from 'antd';
import URI from 'urijs';
import wx from 'weixin-js-sdk';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactEcharts from 'echarts-for-react';
import 'echarts-liquidfill/src/liquidFill.js'

const w = document.documentElement.clientWidth;
const h =  document.documentElement.clientHeight;
const isLandscape = (w > h);
const width = isLandscape ? h: w;
const height = isLandscape ? w: h;


const { Title } = Typography;


const Schedule = (props) =>{

  return (<ReactEcharts style={{ marginTop:'-5%' }} option={{series: [{type: 'liquidFill', radius: props.radius, data: [props.peoplenum/props.totalPeople], color:['#ffc796'],
  label: {
     position: ['30%'],
     formatter: (params) => {  return  params.data*props.totalPeople},
     fontSize: 40,
     color: '#ffc796',
     position: 'inside'
 },

 outline: {
     show: true,
     borderDistance: 8,

     itemStyle: {
         color: 'none',
         style:'dashed',
         borderColor: '#ffc796',
         borderWidth: 2,
         shadowBlur: 0,
         shadowColor: 'rgba(0, 0, 0, 0.25)'
     }
 },

  backgroundStyle: {
       color: '#FFFF',
       borderColor: '#ffc796',
       borderWidth: 0,
       backgroundColor: 'rgba(1, 1, 1, 1)',
       shadowColor: 'rgba(1, 1, 1, 1)',
       shadowBlur: 0
   },}]} }/>);
}


const Schedule2 = (props) =>{

  return (<ReactEcharts style={{ marginTop:'-10%',height: props.height}} option={{series: [{type: 'liquidFill', radius: props.radius, data: [props.peoplenum/props.totalPeople], color:[props.color],
  label: {
     position: ['30%'],
     formatter: (params) => {  return  params.data*props.totalPeople},
     fontSize: 20,
     color: props.color,
     position: 'inside'
 },

 outline: {
     show: true,
     borderDistance: 2,

     itemStyle: {
         color: 'none',
         style:'dashed',
         borderColor: props.color,
         borderWidth: 2,
         shadowBlur: 0,
         shadowColor: 'rgba(0, 0, 0, 0.25)'
     }
 },

  backgroundStyle: {
       color: '#FFFF',
       borderColor: '#ffc796',
       borderWidth: 0,
       backgroundColor: 'rgba(1, 1, 1, 1)',
       shadowColor: 'rgba(1, 1, 1, 1)',
       shadowBlur: 0
   },}]} }/>);
}


class PageSZ3 extends React.Component{

  constructor(){
    super();
    this.state = {
      webUrl :"",
      interface1:"",
      PageFlag: '0',
      themeId:"1",
      number:"5",
      projectId:"",
      sessionId:"",
      answerMode:"1",
      flagId:"",
      answer:"",
      yes:["","", "", "", "","","", "", "", ""],
      answerData:[],
      answerIndex:0,
      fraction:60,
      userTime:0,
      modalVisible : false,
      modalMseeage: "",
      opacity:1,
      groupImage: "椭圆.png",
      headimgurl:"https://t7.baidu.com/it/u=1063451194,1129125124&fm=193&f=GIF",
      data:[{email:11,themeId:1},{email:12,themeId:2},{email:13,themeId:3},{email:14,themeId:4},{email:15,themeId:5},{email:16,themeId:6},{email:17,themeId:7},{email:18,themeId:8},{email:19,themeId:9},{email:20,themeId:10}]
    };
  }


  setOpacity=()=>{

    if (this.state.opacity < 1){
      this.setState({opacity:this.state.opacity+0.05});
      setTimeout(this.setOpacity, 20);
    }

  }





  ceshi=(peopleId)=> {
    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.indexOf('micromessenger') < 0) return false
    const sendData = {
      projectId: this.state.projectId ,
      sessionId:this.state.sessionId ,
      peopleId: peopleId,
      code:this.state.code,
      state:this.getUrlParams('state',this.props.location.search),
    }

    REQUEST.get(API.GETWXINFO, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      const newData = [];
      if (errorCode != "0000"){
          this.info(responseData[0].errorMsg)
        }
      else{

        console.log(responseData);

        wx.config({
          // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: responseData[0].appId, // 必填，公众号的唯一标识
          timestamp: responseData[0].timestamp, // 必填，生成签名的时间戳
          nonceStr: responseData[0].nonceStr, // 必填，生成签名的随机串
          signature: responseData[0].signature,// 必填，签名，见附录1
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
        });

        console.log('okkk')
        wx.ready(function () {

          wx.updateAppMessageShareData({
            // debug: true,
            title: '习近平新时代中国特色社会主义思想主题答题', // 分享标题
            desc: '答题结果', // 分享描述
            link:  document.location.href,
            imgUrl: "https://bayi-bucket.oss-cn-beijing.aliyuncs.com/answer/shenzhen001/logo.jpg", // 分享图标
            // type: '', // 分享类型,music、video或link，不填默认为link
            // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              console.log('okkk11111111111111111111111')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })

          console.log(document.location.href);
          //分享到朋友圈
          wx.updateTimelineShareData({
            title: '习近平新时代中国特色社会主义思想主题答题', // 分享标题
            desc: '答题结果', // 分享描述
            link: document.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://bayi-bucket.oss-cn-beijing.aliyuncs.com/answer/shenzhen001/logo.jpg", // 分享图标
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
        });
      }
    })


  }


  generateGetCodeUrl =(redirectURL)=> {

    return new URI("https://open.weixin.qq.com/connect/oauth2/authorize")
        .addQuery("appid", "wxb650cd27b8ec8e4d")
        .addQuery("redirect_uri", document.location.href)
        .addQuery("response_type", "code")
        .addQuery("scope", "snsapi_userinfo")
        .addQuery("state", "STATE")
        .addQuery("connect_redirect", "1")
        .hash("wechat_redirect")
        .toString();
 };



  componentWillMount(){

    document.title='答题系统';

    if (this.getUrlParams('code',this.props.location.search) != ""){
          if (window.location.href != document.location.href){
            window.location.href = document.location.href;
          }

          this.setState({
              projectId:this.getUrlParams('projectId',this.props.location.search),
              sessionId:this.getUrlParams('sessionId',this.props.location.search),
              code:this.getUrlParams('code',this.props.location.search),
              groupId:this.getUrlParams('groupId',this.props.location.search) ,
              webUrl:this.getUrlParams('projectId',this.props.location.search) == "shenzhen001"?'ws://39.105.202.145:8004/Answer/ReactWebsocket/':'ws://39.105.202.145:8004/Answer/ReactWebsocket2/'

          });
          const groupId = this.getUrlParams('groupId',this.props.location.search);
          if (groupId == "1"){
            this.setState({groupImage:"红组.png"});
          }
          else if (groupId == "2"){
            this.setState({groupImage:"黄组.png"});
          }
          else if (groupId == "3"){
            this.setState({groupImage:"蓝组.png"});
          }
          else if (groupId == "4"){
            this.setState({groupImage:"绿组.png"});
          }
          else if (groupId == "5"){
            this.setState({groupImage:"紫组.png"});
          }

          this.addHost();
    }
    else{
      document.location = this.generateGetCodeUrl(document.location.href);
    }
  }

  getUrlParams =(name, str) => {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = str.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return "";
  }



    onClick7=()=>{
      const sendData = {
        projectId: this.state.projectId ,
        sessionId:this.state.sessionId ,
        themeId: this.state.answerData[this.state.answerIndex]["themeId"],
        peopleId:this.state.peopleId,
        answer:this.state.answer,
        questionId:this.state.answerData[this.state.answerIndex]["answerId"],
      }
      REQUEST.get(API.SETANSWERKEY, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{
          // if (this.state.answerMode == "1"){
            let yesList = this.state.yes;
            yesList[this.state.answerIndex] = responseData[0].yesFlag;


            const lastFlag = responseData[0].lastFlag;

            this.setState({
              answer:this.state.answerData[this.state.answerIndex]["answer"],
              yes:yesList,})

            if (responseData[0].yesFlag == "yes"){
              this.setState({
                      modalVisible:true,
                      lastFlag:lastFlag,
                      fraction:60,
                      modalMseeage: lastFlag == "true"?"你答对该题，答题完成": "你答对该题，请准备下一题！",})
              // setTimeout(this.setVisibleFalse, 2000);
            }
            else{
              this.setState({
                      modalVisible:true,
                      lastFlag:lastFlag,
                      fraction:60,
                      modalMseeage: lastFlag == "true"?"你答错该题，正确答案为：" + this.state.answerData[this.state.answerIndex]["answer"] + "，答题完成！":"你答错该题，正确答案为：" + this.state.answerData[this.state.answerIndex]["answer"] + "，请准备下一题！",})
              // setTimeout(this.setVisibleFalse, 2000);
            }


            // if (lastFlag == "true"){
            //   setTimeout(this.setLast, 2000);
            // }
            // else{
            //   setTimeout(this.setNext, 2000);
            // }
          }
      });
    }

    countTime=()=>{

      if (this.state.PageFlag == "6"){
        var userTime = this.state.userTime + 1;
        var fraction = this.state.fraction -1;

        // if (fraction<=0){
        //
        //   if (fraction==0){
        //     if (this.state.modalVisible){
        //       this.setVisibleFalse();
        //     }
        //     else{
        //       this.onClick7();
        //       this.setVisibleFalse();
        //     }
        //
        //   }
        //   else{
        //     fraction = 0;
        //   }
        //
        // }



        this.setState({userTime :userTime,
          });
        setTimeout(this.countTime, 1000);

      }
    }

    onSelectClick1=(index)=>{

      this.setState({themeId:index});

      const sendData = {projectId: this.state.projectId ,
      sessionId:this.state.sessionId ,
      themeId: index}
      REQUEST.get(API.CHOOSETHEMEID, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{

        }
      });

    }

    onSelectClick=()=>{

      const sendData = {
        projectId: this.state.projectId ,
        sessionId:this.state.sessionId ,
        themeId: this.state.themeId
      }
      REQUEST.get(API.CHOOSETHEMEID, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
          }
        else{

        }
      });

    }

    onSelectClick2=(option)=>{
      console.log('radio checked', option.target.value)
      this.setState({answer:option.target.value});
    }

    setOpacity1=()=>{
      this.setState({opacity1:"1",
      opacity2:"0.6"});
      this.chooseQuestion("0");
    }

    setOpacity2=()=>{
      this.setState({opacity1:"0.6",
      opacity2:"1"});
      this.chooseQuestion("1");
    }
    setOpacity3=()=>{
      this.setState({opacity3:"1",
      opacity4:"0.6",
    answerMode:"1",});
    }

    setOpacity4=()=>{
      this.setState({opacity3:"0.6",
      opacity4:"1",
    answerMode:"2",});
    }

    OnNumAdd=()=>{
      if (this.state.number < 5){
        this.setState({number:this.state.number+1});
      }
    }

    OnNumReduce=()=>{
      if (this.state.number > 2){
        this.setState({number:this.state.number-1});
      }
    }

    //主持人加入
    addHost=()=>{
      const sendData = {
        projectId: this.getUrlParams('projectId',this.props.location.search) ,
        code:this.getUrlParams('code',this.props.location.search) ,
        themeId:this.getUrlParams('themeId',this.props.location.search) ,
        groupId:this.getUrlParams('groupId',this.props.location.search) ,
        sessionId:this.getUrlParams('sessionId',this.props.location.search)};

      REQUEST.get(API.ADDANSWERPEOPLE, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode != "0000"){
            this.info(responseData[0].errorMsg)
            this.setState({PageFlag: '0',
        });
          }
        else{
            if (responseData[0].pageFlag == "10"){


              const rank = responseData[0].rank;
              if (rank == "1"){
                this.setState({
                  peopleId: responseData[0].peopleId,
                  headimgurl:responseData[0].url,
                  golden:responseData[0].detailmsg.replace("\\n", ""),
                explain:responseData[0].explain,
                PageFlag:9})
              }
              else{

                this.setState({
                  peopleId: responseData[0].peopleId,
                  headimgurl:responseData[0].url,
                  rank: responseData[0].rank ,
                PageFlag:8});
              }
              console.log(this.state.headimgurl);
              return;

            }
            else{
              this.setState({PageFlag:"1"});
            }
            this.ceshi(responseData[0].peopleId);


            this.setState({
              peopleId: responseData[0].peopleId,
              headimgurl:responseData[0].headimgurl,
              answerMode:responseData[0].answerMode,
            });
            const newData = [];

            let resultCount = Number(responseData[0].resultCount);
                for (let i = 0; i < resultCount; i++) {
                  newData.push({
                    key: responseData[i].answerId,
                    describe: (i+1) + "、"+responseData[i].describe,
                    TitleURL: responseData[i].TitleURL,
                    optionA:  "A：" + responseData[i].optionA,
                    optionB:  "B：" + responseData[i].optionB,
                    optionC : responseData[i].optionC ==""? "":"C：" + responseData[i].optionC,
                    optionD : responseData[i].optionD==""?"":"D：" + responseData[i].optionD,
                    answer: responseData[i].answer,
                    status: responseData[i].status,
                    postscriptType:responseData[i].postscriptType,
                    productId:responseData[i].projectId,
                    themeId:responseData[i].themeId,
                    answerId:responseData[i].answerId,
                  });
                }
              this.setState({answerData:newData});

              if (responseData[0].pageFlag == "9"){

                const titleId = parseInt( responseData[0].titleId);
                this.setState({answerIndex: titleId,
                PageFlag:6});
                setTimeout(this.countTime, 1000);

              }
            }
          });
        }


    info = msg => {
      Modal.info({
        title: msg,
        centered:true,
        width:'80%',
        onOk() {},
      });
    }

    info2 = msg => {
      Modal.info({
        title: msg,
        centered:true,
        width:'80%',
        okButtonProps:{disabled:true},
      });
    }

    onMessage=value=>{


      if (value == "OK"){
        return;
      }
      const responseData = JSON.parse(value);

      const interface1 =responseData[0].interface

      if (this.state.interface1 == interface1){
        return;
      }
      this.setState({interface1:interface1});


      if (interface1 == "006")
      {
        if (this.state.PageFlag != "6"){
          this.setState({PageFlag:"6",
          opacity:0});
          setTimeout(this.setOpacity, 100);
          setTimeout(this.countTime, 1000);
        }

      }
      else if (interface1 == "008"){
        console.log(value);
        if (this.state.answerMode == "1"){
          const peopleId  =responseData[0].peopleId;
          console.log(this.state.peopleId);

          let resultCount = Number(responseData[0].resultCount);
          for (let i = 0; i < resultCount; i++) {
            if (this.state.peopleId.toString() == responseData[i].peopleId.toString()){

              this.setState({rank:i+1});
              //设置名次
              setTimeout(this.setRank, 6000);
            }

          }

          if (this.state.peopleId.toString() == peopleId.toString()){

            this.setState({golden:  responseData[0].golden,
            explain:responseData[0].explain,})


            setTimeout(this.setFirst, 15000);

          }
        }
        else {
          const groupId  =responseData[0].groupId;
          console.log(this.state.peopleId);

          let resultCount = Number(responseData[0].resultCount);
          for (let i = 0; i < resultCount; i++) {
            if (this.state.groupId.toString() == responseData[i].groupId.toString()){

              this.setState({rank:i+1});
              //设置名次
              setTimeout(this.setRank, 6000);
            }

          }

          if (this.state.groupId.toString() == groupId.toString()){

            this.setState({golden:responseData[0].golden,
            explain:responseData[0].explain,})

            setTimeout(this.setFirst, 15000);

          }
        }

      }
    }

    setLast=()=>{

        this.setState({
          answer:"",
          PageFlag:"7",
          opacity:0,
        });
        setTimeout(this.setOpacity, 100);
    }

    setNext=()=>{
      this.setState({
        answer:"",
        opacity:0,
        fraction:60,
        answerIndex:this.state.answerIndex + 1});
        setTimeout(this.setOpacity, 50);
    }


    setVisibleFalse=()=>{
      if (this.state.nowYesFlag == "yes"){
        let yesList = this.state.yes;

        if (this.state.nowPeoPleId = this.state.peopleId){
          yesList[this.state.answerIndex] = this.state.nowYesFlag;

          this.setState({fraction:this.state.fractions})
        }
        else{
          yesList[this.state.answerIndex] = "no";
        }

        const lastFlag = this.state.nowLastFlag;
        if (lastFlag == "true"){
          this.setState({yes:yesList,
            answer:"",
            PageFlag:"7",
            opacity:0,
          });
          setTimeout(this.setOpacity, 50);
          //this.getResult();
        }
        else{
          this.setState({yes:yesList,
            answer:"",
            opacity:0,
            answerIndex:this.state.answerIndex + 1});
            setTimeout(this.setOpacity, 50);
        }
      }

      this.setState({modalVisible:false,})

      if (this.state.lastFlag == "true"){
        this.setLast();
      }
      else{
        this.setNext();
      }
    }

    setFirst=()=>{
      this.setState({PageFlag: "9",
        opacity:0,
        answer:"",});
        setTimeout(this.setOpacity, 50);
    }

    setRank=()=>{
      this.setState({PageFlag: "8",
        opacity:0,});
        setTimeout(this.setOpacity, 50);
    }

    onOpen=value=>{
      this.sendMessage(this.state.sessionId);
    }

    onClose=()=>{
      console.log("111111111111111111112");
    }


    sendMessage=(message)=>{
      this.refWebSocket.sendMessage(message);
    }

    formatSeconds=(value)=> {
        var secondTime = parseInt(value);// 秒
        var minuteTime = 0;// 分
        var hourTime = 0;// 小时
        if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
            //获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60);
            //获取秒数，秒数取余，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            //如果分钟大于60，将分钟转换成小时
            if(minuteTime > 60) {
                //获取小时，获取分钟除以60，得到整数小时
                hourTime = parseInt(minuteTime / 60);
                //获取小时后取余的分，获取分钟除以60取余的分
                minuteTime = parseInt(minuteTime % 60);
            }
        }
        var result = "" + parseInt(secondTime)<10? ("0" + parseInt(secondTime)):parseInt(secondTime);

        if(minuteTime > 0) {
          result = "" + (parseInt(minuteTime)<10? ("0" + parseInt(minuteTime)):(parseInt(minuteTime)))+ ":" + result;
        }
        else{
          result = "00" + ":" + result;
        }
        if(hourTime > 0) {
          result = "" + (parseInt(hourTime)<10? ("0" + parseInt(hourTime)):(parseInt(hourTime))) + ":" + result;
        }
        else{
          result = "00" + ":" + result;
        }

        return result;
    }

    render(){
            return(

              <div style={{backgroundcolor:'#fffdf8'}}>
              {this.state.webUrl !=""?
                <ReactWebsocket
                  url={this.state.webUrl}
                  onMessage={this.onMessage} //接受信息的回调
                  onOpen={this.onOpen} //websocket打开
                  onClose={this.onClose} //websocket关闭
                  reconnect={true}
                  debug={true}
                  ref={Websocket => {
                  this.refWebSocket = Websocket;
                  }}
                />:null}
                {this.state.PageFlag == "1" ? <div style={{  textAlign: 'center', alignItems:'center',opacity: this.state.opacity, backgroundColor:'#fffdf8', height:height}}>
                <img style={{width: width, height: 'auto' }} src={require('../img/党徽2.jpg')}>
                </img>
                <img style={{width: width*0.3, height: 'auto',marginTop:'2%' }} src={require('../img/等待.png')}>
                </img>
                </div>:null }
                {this.state.PageFlag == "6" ?
                <div style={{opacity: this.state.opacity,}}>
                <div style={{backgroundColor: '#edededff', height:height*0.2}}> </div>
                <img style={{width: width*0.4, height: 'auto', position: 'absolute', top:'6%', left:width*0.3 }} src={require('../img/' + this.state.groupImage)}>
                </img>
                <img style={{width: width*0.38, height: width*0.38, position: 'absolute', top:'6.6%', left:width*0.31 , borderRadius:width*0.19}} src={this.state.headimgurl}>
                </img>
                <div style={{width: width*0.7,marginTop:'20%', marginLeft:width*0.15}}>
                <Row justify="center">
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[0]=="yes" ? require('../img/已答正确.png'):this.state.yes[0]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,  }} src={this.state.yes[1]=="yes" ? require('../img/已答正确.png'):this.state.yes[1]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,  }} src={this.state.yes[2]=="yes" ? require('../img/已答正确.png'):this.state.yes[2]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,}} src={this.state.yes[3]=="yes" ? require('../img/已答正确.png'):this.state.yes[3]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[4]=="yes" ? require('../img/已答正确.png'):this.state.yes[4]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,  }} src={this.state.yes[5]=="yes" ? require('../img/已答正确.png'):this.state.yes[5]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[6]=="yes" ? require('../img/已答正确.png'):this.state.yes[6]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[7]=="yes" ? require('../img/已答正确.png'):this.state.yes[7]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[8]=="yes" ? require('../img/已答正确.png'):this.state.yes[8]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,}} src={this.state.yes[9]=="yes" ? require('../img/已答正确.png'):this.state.yes[9]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  </Row>
                </div>

                <div style={{ marginTop:'1%', justify:'center', textAlign: 'center' , width:width }}>
                <h style={{  fontSize:15 }} >
                {"使用时间：" + this.formatSeconds(this.state.userTime)}
                </h>

                </div >
                <div style={{ marginTop:'4%'}}>
                <div style={{marginLeft:width*0.1, marginRight:width*0.1, width:width*0.8}}>
                <h style={{ fontSize:15,  width:width*0.72}} >
                {this.state.answerData[this.state.answerIndex]["describe"]}
                </h>
                </div>
                {this.state.answerData[this.state.answerIndex]["TitleURL"] == ""?null:<img style={{width: width*0.8, height: 'auto',  marginTop:'5%',marginLeft:width*0.1 }} src={this.state.answerData[this.state.answerIndex]["TitleURL"]}/>}
                <div>
                <Radio.Group style={{width: width*0.7,marginLeft:width*0.15, marginTop:'5%'}} onChange={this.onSelectClick2} value={this.state.answer}>
                 <Space direction="vertical" >
                  <Radio  style={{borderRadius:30, width: width*0.7,marginTop:'5%'}}  value={"A"} >{this.state.answerData[this.state.answerIndex]["optionA"]}</Radio>
                  <Radio  style={{borderRadius:30, width: width*0.7,marginTop:'5%'}} value={"B"} >{this.state.answerData[this.state.answerIndex]["optionB"]}</Radio>
                  {this.state.answerData[this.state.answerIndex]["optionC"] == ""? null:  <Radio style={{borderRadius:30,width: width*0.7,marginTop:'5%'}} value={"C"}>{this.state.answerData[this.state.answerIndex]["optionC"]}</Radio>}
                  {this.state.answerData[this.state.answerIndex]["optionD"] == ""? null:  <Radio style={{borderRadius:30,width: width*0.7,marginTop:'5%'}} value={"D"}>{this.state.answerData[this.state.answerIndex]["optionD"]}</Radio>}
                  </Space>
                </Radio.Group>
                </div>
                </div>
                <img style={{width: width*0.2, height: 'auto', marginTop:'10%', marginBottom:'10%', marginLeft:width*0.4 }} src={require('../img/答题确定.png')} onClick={this.onClick7}>
                </img>
                <div style={{height: 0.05*height,backgroundcolor:'#fffdf8'}}></div>
                <Modal title={null} visible={this.state.modalVisible}  footer={<Button  onClick={this.setVisibleFalse}>OK</Button>} closable={false} width='85%' centered={true}  style={{height: 300,  textAlign: 'center', alignItems:'center', borderRadius:50}} bodyStyle={{ borderRadius:50}} >
                <p style={{marginTop: 20}}> {this.state.modalMseeage}</p>
                </Modal>


                </div>:null}
                {this.state.PageFlag == "7"   || this.state.PageFlag == "8"  ||this.state.PageFlag == "9" ?
                <div style={{opacity: this.state.opacity,}}>
                <div style={{backgroundColor: '#edededff', height:height*0.27}}> </div>
                <img style={{width: width*0.5, height: 'auto', position: 'absolute', top:'7%', left:width*0.25 }} src={require('../img/' + this.state.groupImage )}>
                </img>
                <img style={{width: width*0.48, height: width*0.48, position: 'absolute', top:'7.55%', left:width*0.26 , borderRadius:width*0.24}} src={this.state.headimgurl}>
                </img>
                </div>:null}
                {this.state.PageFlag == "7"  ?
                <div style={{opacity: this.state.opacity,}}>
                <img style={{width: width*0.3, height: 'auto',  marginTop:'20%', marginLeft:width*0.35 }} src={require('../img/完成.png')}>
                </img>
                </div>:null}

                {this.state.PageFlag == "8" ?
                <div style={{opacity: this.state.opacity,}}>
                <img style={{width: width*0.7, height: 'auto', marginTop:'20%', marginLeft:width*0.15 }} src={require('../img/第' + this.state.rank + '名.png')}>
                </img>
                <img style={{width: width*0.12, height: 'auto', marginTop:'1%', marginLeft:width*0.44 }} src={require('../img/得分.png')}>
                </img>
                <div style={{width: width*0.7,marginTop:'5%', marginLeft:width*0.15}}>
                <Row justify="center">
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[0]=="yes" ? require('../img/已答正确.png'):this.state.yes[0]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,  }} src={this.state.yes[1]=="yes" ? require('../img/已答正确.png'):this.state.yes[1]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,  }} src={this.state.yes[2]=="yes" ? require('../img/已答正确.png'):this.state.yes[2]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,}} src={this.state.yes[3]=="yes" ? require('../img/已答正确.png'):this.state.yes[3]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[4]=="yes" ? require('../img/已答正确.png'):this.state.yes[4]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,  }} src={this.state.yes[5]=="yes" ? require('../img/已答正确.png'):this.state.yes[5]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[6]=="yes" ? require('../img/已答正确.png'):this.state.yes[6]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[7]=="yes" ? require('../img/已答正确.png'):this.state.yes[7]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04, }} src={this.state.yes[8]=="yes" ? require('../img/已答正确.png'):this.state.yes[8]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  <Col span={2}><img style={{width: width*0.04, height: width*0.04,}} src={this.state.yes[9]=="yes" ? require('../img/已答正确.png'):this.state.yes[9]=="no" ?require('../img/已答错误.png'):require('../img/未答.png')}/></Col>
                  </Row>
                </div>
                </div>
                :null}

                {this.state.PageFlag == "9" ?
                <div style={{ opacity: this.state.opacity,}}>
                <img style={{width: width*0.3, height: 'auto', marginTop:'14%', marginLeft:width*0.35 }} src={require('../img/获得金句.png')}>
                </img>

                <div style={{marginTop:'5%',marginLeft:width*0.08, marginRight:width*0.08}}>
                <h style={{fontSize:12,  lineHeight:1.5 }}>
                &emsp;&emsp;{this.state.golden}
                </h>
                <div style={{marginTop:'2%',textAlign:'center'}}>
                <h style={{  fontSize:12 }}>
                 {this.state.explain}
                </h>
                </div>

                </div>
                <img style={{width: width*0.2, height: 'auto',   marginTop:'10%', marginLeft:width*0.4, }} src={require('../img/祥云.png')} >
                </img>
                <img style={{width: width, height: 'auto', marginTop:'5%'}} src={require('../img/分享提示.png')}>
                </img>
                </div>
                :null}

             </div>
            )
             }

  }


export default PageSZ3;
