import React , { useState,useEffect }  from 'react';
import {
  Form,
  Select,
  InputNumber,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Layout,
   Menu, Breadcrumb
} from 'antd';
import '../App.less';
import Home from './Home';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
const { Option } = Select;
const { Title } = Typography;
const { Header, Content, Footer ,Sider} = Layout;

const w = document.documentElement.clientWidth;
const h =  document.documentElement.clientHeight;
const isLandscape = (w > h);
const width = isLandscape ? h: w;
const height = isLandscape ? w: h;

const Page3 = () => {

  document.title='中云加';
  
  const [selectFlag, setSelectFlag] = useState("mail");

  const handleClick= (e)=>{
    setSelectFlag(e.key);
  }
  return (
  <Layout className="layout"  >
  <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
    <div className="logo" />
    <Space>
    <img src={require('../img/logo.png')} style={{height:45,width:'auto',marginRight:10}}/>
    <Menu onClick={handleClick} style={{marginLeft:w*0.5, width:w*0.4, }} theme="dark" mode="horizontal" defaultSelectedKeys={['mail']} >
                  <Menu.Item key="mail">
                  产品查询
                  </Menu.Item>
                  <Menu.Item key="app" >
                  新增产品
                  </Menu.Item>
                  <Menu.Item key="app1" >
                  产品分类
                  </Menu.Item>
                </Menu>
    </Space>
    </Header>

          <Content style={{ backgroundColor:'white'}}>
    {selectFlag=="mail"?
    <Page6 w={w}/>:selectFlag=="app"?<Page4 w={w}  projectId=''/>:<Page5 w={w}/>}
    </Content>

  <Footer style={{ textAlign: 'center' ,backgroundColor:'white' }}>Ant Design ©2018 Created by Ant UED</Footer>
</Layout>
)
}

export default Page3;
