import { Upload, Modal,Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import VideoPlay from "./VideoPlay";
import OSS from 'ali-oss';
import REQUEST from '../util/request';
import API from '../util/api';
import ImgCrop from 'antd-img-crop';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class ZJPicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',

    fileList: [

    ],
    fileList2: [

    ],
    changeSrc : this.props.changeSrc,
    buttonName : this.props.buttonName,
    uploadType : this.props.uploadType,
    maxFileCount : this.props.maxFileCount,
    ImageType : this.props.ImageType,
    imageUrl:this.props.url,
    cdnPath:"https://bayi-bucket.oss-cn-beijing.aliyuncs.com/",

  };

 componentDidMount= ()=> {

   console.log(this.props.url );
   if (this.props.url != "" && this.props.url!= []){

     const fileList = []
     for (let i = 0; i < this.props.url.length; i++) {

       if (this.props.url[i] != ""){
         fileList.push({ uid : this.props.url[i].uid.toString(),
          name : this.props.url[i].url.substr(this.props.url[i].url.lastIndexOf("/")+1),
          status: 'done',
          url : this.props.url[i].url,})
        }
       }
       this.setState({fileList: fileList,})

     // fileList: [ {
     //   uid : "-1",
     //   name : this.props.url.substr(this.props.url.lastIndexOf("/")),
     //   status: 'done',
     //   url : this.props.url,}
     // ],
   }
   else{
     this.setState({fileList: []})
   }

 }
 client = self => {
    return new OSS({
      region: 'oss-cn-beijing',
      accessKeyId: 'LTAI5t9CoWf74U6GyUEPjebE',
      accessKeySecret: '03p24Wq1cZ0Lp9JM1buGUfo10HpoaP',
      bucket: 'bayi-bucket',
    });
  };

  uploadPath = (path, file) => {
     // return `${path}/${file.name.split('.')[0]}-${file.uid}.${file.type.split('/')[1]}`;
     return `${path}/${file.name}`;
  };

  UploadToOss = (self, path, file) => {
    const url = this.uploadPath(path, file);
    return new Promise((resolve, reject) => {
      this.client(self)
        .multipartUpload(url, file)
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  beforeUpload = file => {
    const floder =  "zhanjiang/" + this.props.themeId + "/" + this.props.infoName;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.UploadToOss(this, floder, file)
        .then(data => {

          return data;
        })
        .then(data => {
          this.client().putACL(data.name, 'public-read-write');
          // changeSrc(${cdnPath}${data.name}?uploadId=video);
          console.log(this.state.cdnPath + data.name );


          const fileList1 =this.state.fileList;

          if (this.state.uploadType == "image/*"){
            fileList1.push({
                uid : "-1",
                name : data.name,
                status: 'done',
                url : this.state.cdnPath + data.name,

              })
          }
          else{
            fileList1.push({
              uid : "-1",
              name : data.name,
              status: 'done',
              url : this.state.cdnPath + data.name,

            })
          }
           this.setState({ imageUrl : this.state.cdnPath + data.name ,
           });

           if (this.props.infoId != ""){
              this.updateUrl(this.state.cdnPath + data.name);
              this.setState({fileList:fileList1});
            }
            else{
              this.props.handleGiftAid(fileList1);
            }


            // setFileList(fileList1);
            // console.log(fileList.length);
            // changeShow(false);

        });
    };

    return false;
  };


  updateUrl=(address)=>{

    // if (this.props.markId == null){
    //   const sendData = {productId: this.props.productId,
    //   userId:this.props.userId,
    //   ImageType:this.props.ImageType,
    //   fileUrl:this.state.imageUrl,
    //
    //   };
    //   REQUEST.get(API.UPDATEURL, sendData, (responseData)=>{
    //     let errorCode = responseData[0].errorCode;
    //     console.log(responseData[0]);
    //
    // });
    // }
    // else{

      const sendData = {
        infoId: this.props.infoId,
        address:address,
        picType:this.state.ImageType,

      };
      REQUEST.get(API.ZJADDMARKPOINTPNG, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        console.log(responseData[0]);

    });
    // }

  }



  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => { console.log(fileList) ;
    if (fileList.length < this.state.fileList.length){
      this.setState({ fileList : fileList });
    }
    }
  handleRemove = (file) => {
    console.log(file.name)
    const floder = "zhanjiang/" + this.props.themeId+"/" + this.props.infoName + "/";
    this.client().delete(floder+file.name);
    //删除对应的图片
    // if (this.props.markId == null){
    //   const sendData = {productId: this.props.productId,
    //   userId:this.props.userId,
    //   ImageType:this.props.ImageType,
    //   fileUrl:"",
    //
    //   };
    //   REQUEST.get(API.UPDATEURL, sendData, (responseData)=>{
    //     let errorCode = responseData[0].errorCode;
    //     console.log(responseData[0]);
    //
    // });
    // }
    // else{

      if (this.props.infoId != ""){
        const sendData = {infoId: this.props.infoId,
          ImageId:file.uid,
          address:this.state.cdnPath + floder+file.name,
          picType:this.state.ImageType,

        };
        REQUEST.get(API.ZJDELTEMARKPOINTPNG, sendData, (responseData)=>{
          let errorCode = responseData[0].errorCode;
          console.log(responseData[0]);

      });
      }

    // }
  }

  render() {
    const props = {
            width: 500,  //裁剪宽度
            height: 300, //裁剪高度
            resize: false, //裁剪是否可以调整大小
            resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
            modalTitle: "上传图片", //弹窗标题
            modalWidth: 600, //弹窗宽度
        };

    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>{this.state.buttonName}</div>
      </div>
    );
    return (
      <>
      {this.state.uploadType == "image/*"?
      <div>

        <Upload
          beforeUpload={this.beforeUpload}
          accept={this.state.uploadType}
          listType="picture-card"
          fileList={fileList}
          onChange={this.handleChange}
          onPreview={this.handlePreview}
          onRemove={this.handleRemove}
        >
          {fileList.length >= this.state.maxFileCount ? null : uploadButton}
        </Upload>

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal></div>:

        <Space>
          <Upload
            beforeUpload={this.beforeUpload}
            accept={this.state.uploadType}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
          >
            {fileList.length >= this.state.maxFileCount ? null : uploadButton}
          </Upload>


          </Space>

      }


      </>
    );
  }
}


export default ZJPicturesWall;
