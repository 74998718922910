import React , { useState,useEffect }  from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined,WindowsFilled, AndroidFilled,AppleFilled,MenuUnfoldOutlined,MenuFoldOutlined} from '@ant-design/icons';
import VideoPlayH5 from './VideoPlayH5';
import VideoPlay from './VideoPlay';

import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Card, Avatar,
  List,
  Menu,
  Layout,
  Anchor,
  Row, Col ,
} from 'antd';

import '../App.less';
import REQUEST from '../util/request';
import API from '../util/api';

const { Link } = Anchor;
const { Title } = Typography;
const { Meta } = Card;
const { Header, Content, Footer ,Sider} = Layout;


const ShowMenu = (props)=>{

  const [selectedKeys, setSelectedKeys] = useState(['2']);

  const onChange = (link: string) => {
  console.log('Anchor:OnChange', link);
  if (link != null){
    if (link == ""){
      setSelectedKeys(['2'])
    }
    else{
      setSelectedKeys([link.split('-')[1]])
    }

  }

};

const handleClick = e => {
    console.log('click ', e);
    setSelectedKeys([ e.key])

  };

  return(

    <Menu
      mode="vertical"
      style={{ height: '100%' }}
      selectedKeys={selectedKeys}
      onClick={handleClick}

    >
    {props.classType.map((item, index) => (

      <Menu.Item key={item.numId}> <Anchor onChange={onChange} affix={false} targetOffset={100} style={{ width: '100%' }}>
                <Link style={{ width: '100%' }} href={"#components-" + item.numId} title={item.classification}/>
                  </Anchor></Menu.Item>

    ))}

  </Menu>);



  // <Menu.Item key="1">          <Anchor affix={false} targetOffset={300} >
  //           <Link href="#components-anchor-demo-basic" title="Basic demo" />
  //             </Anchor></Menu.Item>
  // <Menu.Item key="2"><Anchor affix={false} targetOffset={300} >
  //           <Link href="#components-anchor-demo-static" title="Static demo" />
  //             </Anchor></Menu.Item>


// const showItem=(item)=>{
//   return(
//     <Menu.Item key={item.numId}>          <Anchor affix={false} targetOffset={300} >
//               <Link href="#components-11" title={item.classification}/>
//                 </Anchor></Menu.Item>
//   )
// }
}


const ShowProduct = (props)=>{


  return props.classType.map((item1, index) => (

    <div>
  <Title style={{marginLeft:props.w>768?60:10 }} level={5} > {props.classType[index].classification}</Title>
    <List  key={props.classType[index].numId} id={"components-" + props.classType[index].numId} style={{  marginRight:props.w>768?60:10,marginLeft:props.w>768?60:10, width:props.w>768?props.w-340:props.w-20}} bordered={false} split = {false}
                  grid={{ gutter: props.w>768?50:10, column: props.w>768?4:2 }}
                  dataSource={ props.productData[props.classType[index].numId]}
                  renderItem={item => (
                    <List.Item key={item.productId} onClick={()=>props.handleGiftAid(item)} >
                      <Card style={{ borderRadius:15}} cover={<img alt="example" src={item.pngUrl} style={{ height:props.w>768?props.w/10:props.w/3.5,}}/>}  bordered={true} hoverable={true}>
                      <Meta title={item.productName}
                      description={item.remark}/></Card>
                    </List.Item>
                  )}
                />
                </div>

  ));


  // <Menu.Item key="1">          <Anchor affix={false} targetOffset={300} >
  //           <Link href="#components-anchor-demo-basic" title="Basic demo" />
  //             </Anchor></Menu.Item>
  // <Menu.Item key="2"><Anchor affix={false} targetOffset={300} >
  //           <Link href="#components-anchor-demo-static" title="Static demo" />
  //             </Anchor></Menu.Item>


// const showItem=(item)=>{
//   return(
//     <Menu.Item key={item.numId}>          <Anchor affix={false} targetOffset={300} >
//               <Link href="#components-11" title={item.classification}/>
//                 </Anchor></Menu.Item>
//   )
// }
}

const data1 = [
  {
    title: 'Title 1',
  },
  {
    title: 'Title 2',
  },
  {
    title: 'Title 3',
  },
  {
    title: 'Title 4',
  },
  {
    title: 'Title 5',
  },
  {
    title: 'Title 6',
  },
  {
    title: 'Title 7',
  },
  {
    title: 'Title 8',
  },
  {
    title: 'Title 9',
  },
  {
    title: 'Title 10',
  },
  {
    title: 'Title 11',
  },
  {
    title: 'Title 12',
  },
];
class Home extends React.Component{


  constructor(){
    super();
    const self =this;

    this.formRef = React.createRef();


    this.state = {
    playFlag:false,
    classType:[],
    productData:[],
    contentLength:200,
    collapsed:false,
    mobileFlag:false,
  }
}


  componentDidMount () {

    const sendData={}
    REQUEST.get(API.SELECTCLASS, sendData, (responseData)=>{

      let errorCode = responseData[0].errorCode;
      if (errorCode == "0000"){
        const classType = [];
        let resultCount = Number(responseData[0].resultCount);

            for (let i = 0; i < resultCount; i++) {
              console.log(resultCount);
              console.log(responseData[i].numId);
              console.log(responseData[i].classification);
              classType.push({classification:responseData[i].classification,numId: responseData[i].numId})



            }
            this.onSelectClick(classType);


            console.log(classType);
          }
        });

    if (this.props.w <768){
      this.setState({
        contentLength:0,
        collapsed:true,
        mobileFlag:true,
      })
    }


  }

  onSelectClick=(classType)=>{


    const productData = {};
    const sendData = [];

    for (let i=0; i<classType.length; i++){
      console.log(classType[i].numId);
      const sendData = {numId:classType[i].numId};
      REQUEST.get(API.DOSELECTPRODUCT, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode == "0000"){
          let resultCount = Number(responseData[0].resultCount);
              for (let j = 0; j < resultCount; j++) {
                newData.push({
                  key: responseData[j].productId,
                  productId: responseData[j].productId,
                  productName: responseData[j].productName,
                  classification: responseData[j].classification,
                  reamrk: responseData[j].reamrk,
                  workDate: responseData[j].workDate,
                  hasVideo: responseData[j].hasVideo,
                  hasWind: responseData[j].hasWind,
                  hasIos: responseData[j].hasIos,
                  hasAndroid: responseData[j].hasAndroid,
                  pngUrl: responseData[j].pngUrl,
                  videoUrl: responseData[j].videoUrl,
                  iosUrl: responseData[j].iosUrl,
                  androidUrl: responseData[j].androidUrl,
                  videoUrl: responseData[j].videoUrl,
                  winUrl: responseData[j].winUrl,
                  explain:responseData[j].explain,
                  useScenario:responseData[j].useScenario,
                });
              }
          }
          console.log(classType[i].numId);
          productData[classType[i].numId] = newData;
          console.log(productData);
          if (i ==(classType.length-1 )){
            this.setState({productData:productData});
            console.log(productData);
          }
          this.setState({classType:classType});

      });
    }


  }


    // projectId:this.getUrlParams('projectId',this.props.location.search),
    // userId:this.getUrlParams('userId',this.props.location.search),



  // const handleOk = ()=>{
  //   // setPlayFlag(false);
  //   this.setState({playFlag:false})
  // }
  //
  //
  //
  // const handleCancel = ()=>{
  //   setPlayFlag(false);
  //   Modal.destroyAll();
  // }

   handleOk = ()=>{
    // setPlayFlag(false);
    this.setState({playFlag:false})

  }

  setPlayFlag = (item)=>{
   // setPlayFlag(false);
   console.log("------------------------");
   this.setState({
     dealItem:item,

     playFlag:true});

   console.log(item);
 }



   handleCancel = ()=>{
      this.setState({playFlag:false})
    Modal.destroyAll();
  }


  showSider = ()=>{
    this.setState({
      collapsed:!this.state.collapsed,
    })
  }


  render(){
    // <Header style={{ position: 'fixed', zIndex: 1, width: '100%' ,width:this.props.w,backgroundColor:'#dcdee0',height:200}}>
    // <div style={{ width:this.props.w,height:200}}>
    // <div style={{marginLeft:this.props.w*0.05,  position: 'fixed', width:this.props.w,height:200,zIndex: 1}}>
    // <Row align="middle" justify="center" >
    // <Col span={16}>
    // <Title style={{marginTop:25}}>中云加,  科技改变工作方式</Title>
    // <Title level={4} strong={false}>专注科技化营销展示及管理应用系统</Title>
    // <Title level={4} style={{marginBottom:100}}>为客户提供线上线下科技化营销展示管理等应用服务</Title>
    // </Col>
    // <Col span={8}>
    //  <img src={require('../img/logo2.png')} style={{width:'auto',height:100, }}/>
    //  </Col>
    //  </Row>
    // </div>
    // </div>
    // </Header>
  return (
    <div style={{ marginTop:this.state.mobileFlag?20 :60, }}>

    {this.state.mobileFlag?
      this.state.collapsed? <MenuUnfoldOutlined width={30}  onClick={this.showSider} style={{marginLeft:10,position:'fixed',zIndex: 1, color:'#fff', width:30}}></MenuUnfoldOutlined>:<MenuFoldOutlined width={30}  onClick={this.showSider} style={{marginLeft:10,position:'fixed',zIndex: 1, color:'#fff', width:30}}></MenuFoldOutlined>:
       null}
    <Layout className="site-layout-background" style={{ marginTop:this.state.mobileFlag?40 :0,  }}>


  <Content >
  <Layout className="site-layout-background">
    <Sider   style={{
        overflow: 'auto',
        position: 'fixed',
        height: this.props.h-68 ,
         zIndex: 1,
         backgroundColor:'white',
         marginTop:this.state.mobileFlag?30 :10,


      }}
      collapsed = {this.state.collapsed}
      collapsedWidth = {0}
      width={200}>
            <Menu
              mode="inline"
              defaultSelectedKeys={['2']}
              style={{ height: '100%' }}

            >

            <ShowMenu classType={this.state.classType}/>


            </Menu>
          </Sider>
            <Content style={{ marginLeft:this.state.contentLength}}>
            <div style={{height:40}}></div>
            <div style={{}}>
            <ShowProduct  classType={this.state.classType} productData={this.state.productData}  handleGiftAid={(item)=>this.setPlayFlag(item)} w={this.props.w}/>

            </div>

            <Modal visible={this.state.playFlag} onOk={this.handleOk} onCancel={this.handleCancel} destroyOnClose={true} width={this.props.w*0.93} footer={null} wrapClassName={'web'}>
                    {this.state.playFlag?
                      <div >
                      {this.state.mobileFlag? <VideoPlayH5 dealItem={this.state.dealItem} width={this.props.w*0.93} />:<VideoPlay dealItem={this.state.dealItem} width={this.props.w*0.93} />}

                      </div>
                  :null}
                  </Modal>
      </Content>
      </Layout>
      </Content>
      </Layout>
    
 </div>



  );
}
}

export default Home;
