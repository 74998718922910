import React , { useState }  from 'react';
import REQUEST from '../util/request';
import API from '../util/api';
import 'moment/locale/zh-cn';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';


import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Drawer,
  Col,
  Row,
  InputNumber

} from 'antd';

moment.locale('zh-cn');
const { TextArea } = Input;
const originData = [];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = title === '是否有发票' ? <Select style={{ width: 100 }} >
      <Option value="无发票">无发票</Option>
      <Option value="有发票">有发票</Option>

      </Select>: <TextArea autoSize={{ minRows: 1, maxRows: 5 }}/>;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [addFlag, setAddFlag] = useState(false);
  const [type, setType] = useState("1");
  const [loadData, setLoadData] = useState(true);

  const isEditing = record => record.key === editingKey;



  const edit = record => {

    form.setFieldsValue({
      workDate: '',
      classification: '',
      ...record,
    });
    setEditingKey(record.key);
    setAddFlag(false);
  };

  const cancel = (key) => {
    if (addFlag){
      handleDelete(key);
    }
    setEditingKey('');
  };

  const handleTypeChange= value =>{
    setType(value)
  }

  //查询产品分类信息
  const onSelectClick=()=>{

    const sendData = { }
    REQUEST.get(API.SELECTCLASS, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      const newData = [];
      if (errorCode == "0000"){
        setEditingKey('');
        let resultCount = Number(responseData[0].resultCount);
            for (let i = 0; i < resultCount; i++) {
              newData.push({
                key: responseData[i].numId,
                classification: responseData[i].classification,
                workDate:responseData[i].workDate,
              });
            }
          setData(newData);

        }
    });
  }

  const doLoadData=()=>{
    // console.log(props)
    if (loadData != props.loadData){
      onSelectClick();
      setLoadData(!loadData);
    }
  }

   doLoadData();

  const info = msg => {
  Modal.info({
    title: msg,

    onOk() {},
    });
  }

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(item => key.toString() === item.key);

      const sendData = row;
      sendData["numId"] = key.toString();

        REQUEST.get(API.UPDATECLASS, sendData, (responseData)=>{
          let errorCode = responseData[0].errorCode;
          if (errorCode == "0000"){
            info(responseData[0].errorMsg);
            onSelectClick();
            setEditingKey('');
          }
        });

    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }


  };


  const handleDelete = key => {

    const sendData = {numId: key.toString() }
    REQUEST.get(API.DELETECLASS, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      const newData = [];
      if (errorCode == "0000"){
        info(responseData[0].errorMsg);
        onSelectClick();
    }});


  };

  const columns = [
    {
      title: '日期',
      dataIndex: 'workDate',
      key: 'workDate',
      width: '15%',
      editable: false,
    },
    {
      title: '分类名称',
      dataIndex: 'classification',
      key: 'classification',
      width: '60%',
      editable: true,
    },
    {
    title: '操作',
    dataIndex: 'operation',
    width: '15%',
    render: (_, record) => {
      const editable = isEditing(record);

      return editable ? (
        <span>
          <a

            onClick={() => save(record.key)}
            style={{
              marginRight: 8,
            }}
          >
            保存
          </a>
          <Popconfirm title="确认取消？" cancelText="取消" okText = "确认" onConfirm={()=> cancel(record.key)}>
            <a>取消</a>
          </Popconfirm>
        </span>
      ) : (
        <Space size="small">
        <a disabled={editingKey !== ''} onClick={() => edit(record)}>
          修改
        </a>
        <a disabled={editingKey !== ''} onClick={() => handleDelete(record.key)}>
          删除
        </a>
        </Space>

      ) ;
    },
  },



  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
    <Form.Item label="分类信息">
      <Table
          components={{
          body: {
            cell: EditableCell,
          },
        }}
        width={1500}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
      </Form.Item>

      </Form>

  );
}

const { Option } = Select;

class Page5 extends React.Component{

  constructor(){
      super();
  this.state = {
      drawerVisible: false,
	    loginSucc: false,
      classification:"",
      loadData:false,

	};
}


  componentWillMount(){


  }





info = msg => {
  Modal.info({
  title: msg,

  onOk() {},
  });
}


onDealClassification= (event) =>{

this.setState({classification:event.target.value});

}

onOpen = () => {
    this.setState({
      drawerVisible: true,
    });
  };

submitSettlement =()=>{

  if (this.state.classification == ""){
    this.info("请输入分类名称！");
    return;
  }



    const sendData = {
      classification:this.state.classification,

    }
    REQUEST.get(API.ADDCLASS, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      if (errorCode == "0000"){
        this.setState({
          loadData:!this.state.loadData,
      })
        this.info(responseData[0].errorMsg);
        this.onClose();
      }
    });

  }
  onClose = () => {
    this.setState({
      drawerVisible: false,
      // loadData:false,
    });
  };

  render(){
    return (
      <div className="clearfix" style={{  marginTop:100}}>
      <Form  labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
      <EditableTable loadData={this.state.loadData} />
      <Form.Item wrapperCol={{ span: 16, offset: 4}}>
        <Space>
          <Button type="primary" htmlType="submit" onClick={this.onOpen} disabled={this.state.projectId==""}>
            新增分类
          </Button>
        </Space>
      </Form.Item>
      </Form>
      {this.state.drawerVisible?
    <Drawer
    title="新增分类"
    placement="right"
    closable={false}
    onClose={this.onClose}
    visible={this.state.drawerVisible}
    key="right"
    bodyStyle={{ paddingBottom: 80 }}
    extra={
      <Space>
        <Button onClick={this.onClose}>取消</Button>
        <Button onClick={this.submitSettlement} type="primary">
          提交
        </Button>
      </Space>
    }
  >
    <Form  layout="vertical" hideRequiredMark>
      <Row gutter={16}>
        <Col span={24}>
        <Form.Item
          label="分类名称"
          rules={[{ required: true }]}
        >
        <Input style={{width: 200 }}  onChange={this.onDealClassification}  value = {this.state.classification}  />
        </Form.Item>

        </Col>
      </Row>

    </Form>


      </Drawer> :null}

      </div>
    );
  }

}


export default Page5;
