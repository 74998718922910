
import { Image } from 'antd';
import React from 'react';
import wx from 'weixin-js-sdk';
import REQUEST from '../util/request';
import API from '../util/api';
import axios from 'axios';

const w = document.documentElement.clientWidth;
const h =  document.documentElement.clientHeight;
const isLandscape = (w > h);
const width = isLandscape ? h: w;
const height = isLandscape ? w: h;

class PagePng extends React.Component{

  constructor(){
    super();
    this.state = {
      filePath: '',}
    }


  componentWillMount(){
    document.title='封开黄岩洞陈列馆';
    this.setState({
        // filePath: "https://bayi-bucket.oss-cn-beijing.aliyuncs.com/faceFusion/" + this.getUrlParams('filePath',this.props.location.search),
        filePath: this.getUrlParams('filePath',this.props.location.search) ,

    });

    console.log(this.state.filePath);
    this.ceshi();
  }


  ceshi=()=> {

    var title = "黄岩洞数字博物馆";
    var desc = "AR拍照系统";
    var imgUrl = "https://img.fkhyd.cn/uploads/yingping/100.jpg";

    axios.get("https://img.fkhyd.cn/ai/system/config.json")
    .then((response)=> {
        //用到this要注意this指向
        console.log(response.data);
        title = response.data.poster.title;
        desc = response.data.poster.desc;
        imgUrl = response.data.poster.img;
    })
    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.indexOf('micromessenger') < 0) return false
    const sendData = {
      filePath: this.getUrlParams('filePath',this.props.location.search) ,
    }

    var Img = require('../img/logo.png');//实际自己的图片路径地址
    var url = window.location.href;
    var hosts = url.split('index.html')[0];//获取域名加打包文件夹名称
    var shareImg = hosts.concat(Img);

    REQUEST.get(API.GETWXINFOPNG, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      const newData = [];
      if (errorCode != "0000"){
          this.info(responseData[0].errorMsg)
        }
      else{

        console.log(responseData);

        wx.config({
          // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: responseData[0].appId, // 必填，公众号的唯一标识
          timestamp: responseData[0].timestamp, // 必填，生成签名的时间戳
          nonceStr: responseData[0].nonceStr, // 必填，生成签名的随机串
          signature: responseData[0].signature,// 必填，签名，见附录1
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
        });

        console.log('okkk')
        wx.ready(function () {
          wx.updateAppMessageShareData({
            // debug: true,
            title: title, // 分享标题
            desc: desc, // 分享描述
            link:  document.location.href,
            imgUrl:"https://img.fkhyd.cn/uploads/yingping/100.jpg", // 分享图标
            // type: '', // 分享类型,music、video或link，不填默认为link
            // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              console.log('okkk11111111111111111111111')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })

          console.log(document.location.href);
          //分享到朋友圈
          wx.updateTimelineShareData({
            title: '黄岩洞数字博物馆', // 分享标题
            desc: 'AR拍照系统', // 分享描述
            link: document.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
        });
      }
    })


  }


  getUrlParams =(name, str) => {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = str.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return "";
    }


    render(){

      return(
        <div style={{backgroundcolor:'#fffdf8'}}>
        <Image
            preview={false}
            width={width}
            src={this.state.filePath}
          />
        </div>
      )
    }
}

export default PagePng;
