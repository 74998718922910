
import { Image} from 'antd';
import React from 'react';
import wx from 'weixin-js-sdk';
import REQUEST from '../util/request';
import API from '../util/api';
import axios from 'axios';

const w = document.documentElement.clientWidth;
const h =  document.documentElement.clientHeight;
const isLandscape = (w > h);
const width = isLandscape ? h: w;
const height = isLandscape ? w: h;

class AppStore extends React.Component{

  constructor(){
    super();
}


  componentWillMount(){
    document.title='隐私协议';

    //this.ceshi();
  }







  getUrlParams =(name, str) => {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = str.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return "";
    }


    render(){
      //src={require('E:/wxy/照片/微信图片_20200323205244.jpg')}
      //src={this.state.filePath}
      //src={process.env.PUBLIC_URL + '/assets/' + this.state.filePath}
      return(
        <div style={{backgroundcolor:'#fffdf8', marginLeft:20,marginRight:20}}>
        <div style={{height:40,  textAlign: 'center', fontSize:25, marginTop:15}}>隐私协议</div>
        <div style={{marginLeft:0}}>
        &#8195;&#8195;本政策适用于“中云加”移动应用 产品或服务（以下简称“本应用”）。
        </div>
        <div style={{marginLeft:0}}>本应用亦被称为“中云加App”作为本应用的其他名称，同样适用于本政策。</div>
        <div style={{marginLeft:0}}>作为等同本应用的其他同名产品“中云加小程序端”、“中云加PC端”，同样适用于本政策。</div>
        <div style={{marginLeft:20}}>本隐私权政策部分将帮助您了解以下内容：</div>
        <div style={{marginLeft:0}}>一、定义及适用范围</div>
        <div style={{marginLeft:0}}>二、我们如何收集和使用您的信息；</div>
        <div style={{marginLeft:0}}>三、我们如何使用Cookie和同类技术；</div>
        <div style={{marginLeft:0}}>四、我们如何共享、转让、公开披露用户的个人信息；</div>
        <div style={{marginLeft:0}}>五、我们如何保护用户的信息安全；</div>
        <div style={{marginLeft:0}}>六、您如何管理个人信息；</div>
        <div style={{marginLeft:0}}>七、我们如何处理未成年人的信息；</div>
        <div style={{marginLeft:0}}>八、您的信息如何在全球范围转移；</div>
        <div style={{marginLeft:0}}>九、本隐私权政策如何更新；</div>
        <div style={{marginLeft:0}}>十、如何联系我们</div>
        <div style={{marginLeft:0}}><p></p>&#8195;&#8195;广州名图数字科技有限责任公司（以下简称“我们”）深知个人信息对您的重要性，我们将按法律法规要求并参照行业最佳实践为您的个人信息安全提供充分保障，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。鉴此，我们制定本《隐私权政策》（下称“本政策 /本隐私权政策”）以帮助您充分了解在您使用我们的产品/服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您如何管理您的个人信息。<p></p>&#8195;&#8195;在使用本应用各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用本应用各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过本应用提供的各种联系方式与我们联系。<br/><br/></div>

        <div style={{marginLeft:0}}>&#8195;&#8195;一、	定义及适用范围</div>
        <div>
个人信息：以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反应特定自然人活动情况的各种信息，个人信息包括（姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息）等。<br/>
个人敏感信息：一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康收到损害或歧视性待遇等的个人信息，个人敏感信息包括（身份证件号码、个人生物识别信息、银行账户、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息）等。<br/>
个人信息处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。<br/>
个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。<br/>
个人信息匿名化：通过对个人信息的技术处理，使得个人信息主体无法被识别或者关联，且处理后的信息不能被复原的过程。<br/>
个人信息去标识化：通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或者关联个人信息主体的过程。<br/>
个人信息主体：个人信息所标识或者关联的自然人。<br/>
个人信息控制者：有能力决定个人信息处理目的、方式等的组织或个人。<br/>
设备信息：包括设备标识符（IMEI、IDFA、Android ID、MAC、OAID、IMSI及其他设备相关信息）、应用信息（应用崩溃信息、通知开关状态、应用安装列表及其他应用相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络 环境信息（IP地址、WiFi信息，基站信息及其他网络相关信息），以产品实际采集情况为准。<br/>
儿童：指不满十四周年的未成年人。<br/><br/></div>
      <div style={{marginLeft:0}}>&#8195;&#8195;二、	我们如何收集和使用您的信息</div>
      <div>
在您使用我们的产品/服务时，我们需要收集和使用您的个人信息包括如下两种：<br/>
1、为实现向您提供我们产品/或服务的基本功能，您须授权我们收集、使用的必要的信息，如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；<br/>
2、为实现向您提供我们产品及/或服务的附加功能，您可选择单独同一或不同意我们收集、使用的信息，如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。<br/>
3、在为你提供同城频道内容或者与设备所在位置相关的内容或服务时，经过你的授权，我们会使用与本服务相关的地理位置信息（如精确定位信息、WLAN接入点、蓝牙和基站、传感器信息），以保障内容或服务的质量。其中精确定位信息是个人敏感信息，若你拒绝授权位置权限会影响定位的准确性，但不会影响其他功能与服务的正常使用，我们也仅会基于网络信息（如IP）对应的城市及/或区县向你展示可能相关的内容或服务。<br/>
4、在您使用App时，会获取方向传感器、陀螺仪设备传感器信息，用于鉴别您是否为真实用户。在您使用录像照相相关功能时，会获取加速度设备传感器信息，用于获取拍摄手机角度。<br/>
我们在此特别提醒您：这些信息并非该业务功能运行所必需，但这些信息对改善服务质量、研发新产品或服务等有非常重要的意义。我们不会强制要求您提供这些信息，您如果拒绝不会对使用该业务功能产生不利影响。<br/>
<p></p>
我们为您提供的各项具体功能场景包括：<br/>
1、帮助您成为我们的用户<br/>
为完成创建账号，以便我们为您提供服务，您需提供以下信息：您的手机号码等信息。当您注册时向我们提供注册所用的手机号码(用于接收验证码)，我们将通过发送短信验证码来验证您的身份是否有效。您可以修改补充您的昵称、性别、生日、头像、实名验证绑定以及您的支付宝账号、微信账户(用于账户提现)等相关信息，这些信息均属于您的“账户信息”。当您使用第三方账号(微信账号、Apple账号)登录时，如果您决定绑定该第三方平台的账号，经过您的第三方平台账号绑定授权，第三方平台会同步一部分您在该第三方平台账号下的相关信息给我们，如用户名、手机号、头像、昵称、地区、性别等(具体以您的授权为准)以及身份验证信息。我们收集上述信息是用于为您提供账号登录服务以及保障您的账户安全，防范安全风险。如果您拒绝授权上述信息，您将无法使用第三方平台的账号登录本应用平台，但不影响我们为您提供的其他产品和正常使用其他服务。
在使用过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供更好的服务和体验：您的生日、职业、兴趣、手机号码。但如果您不提供这些信息，将不会影响使用本应用产品或服务的基本功能。
您提供的上述信息将在您使用本服务期间持续授权我们使用。在您主动申请注销账号时，我们将根据适用法律法规的要求尽快使其匿名或删除您的个人信息。<br/>
2、为您提供服务和需求<br/>
为向您展示内容或服务，我们会收集和使用您在访问或使用本网站或客户端时的设备信息（包括设备标识符（IMEI、IDFA、Android ID、MAC、OAID、IMSI及其他设备相关信息）、应用信息（应用崩溃信息、通知开关状态、应用安装列表及其他应用相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络环境信息(IP地址、WiFi信息、基站信息及其他网络相关信息））、服务日志信息（浏览记录、点击查看记录、搜索查询记录、收藏、关注分享信息、发布信息、以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间）、您提交的基础信息（如昵称、性别、手机号），用来完善您的个人信息。<br/>
3、向您提供商品或服务<br/>
3.1 您向我们提供的信息<br/>
您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如微信基本信息（昵称、头像、性别）、电话号码等；<br/>
您通过我们服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。向本应用提供该其他方的前述个人信息之前，您需确保您已经取得其授权同意。<br/>
3.2 我们在您使用服务过程中收集的信息<br/>
为向您提供搜索结果、了解产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：<br/>
3.2.1 设备信息：<br/>
为了保障应用与服务的安全运行，我们会收集您的设备型号、应用列表（安装列表）、设备名称、设备唯一标识符、登录IP地址、产品版本号、移动网络信息。
我们将在静默/后台状态中收集您的（设备标识符、应用列表、IMEI、IDFA、MAC、姓名、手机号、位置）等信息，用于识别用户的身份信息。<br/>
3.2.2 我们根据您在软件安装及使用中授予的具体权限：本应用在您安装及使用中收集个人信息的目的、方式及范围说明，如下：<br/>
（1）读取照片或拍照权限<br/>
	 当您修改个人资料头像信息、或者需要上传图片或视频时，我们会向您请求相册或相机权限。在部分机型上，相册读取权限表现为“读取存储卡中的内容”。 如您拒绝授权提供相册或相机权限，将无法使用相应功能，但不影响您正常使用本应用的其他功能。<br/>
    （2）写入相册权限<br/>
当您使用下载照片功能的操作时，我们会向您请求相册写入权限或存储卡写入权限。在部分机型上，相册写入权限表现为“修改或删除存储卡中的内容”。<br/>
    （3）拨打电话权限<br/>
为了向您提供拨打电话服务，我们将向您申请开启拨号权限，如果您不同意开启拨号权限，将无法使用此功能，但不会影响其他功能的使用。<br/>
（4）获取应用列表<br/>
为了向您提供推送服务支持，我们将向您申请开启获取应用列表权限，以便于我们在向您推送消息时，授权推送服务商进行链路调节，相互促活被关闭的SDK推送进程，保障您可以及时接收到我们向您推送的消息，如果您不同意开启应用列表权限，将会影响你接收推送消息的成功率。<br/>
(5)获取通讯录权限<br/>
为了便于您添加好友，我们要获取您的通讯录权限，用于读取你的通讯录列表以上传到服务器查找你的好友信息，如您拒绝授权提供通讯录权限，将无法使用相应功能，但不影响您正常使用本应用的其他功能。<br/>
 本应用集成的第三方SDK收集信息的目的、方式、范围说明，如下：<br/>

（1）SDK名称：微信开放平台SDK<br/>
包名：com.tencent.mm<br/>
公司：深圳市腾讯计算机系统有限公司<br/>
用途：登录、分享<br/>
权限：存储的个人文件、网络信息，分享的时候, 点击分享链接打开应用<br/>
隐私政策链接：微信隐私协议<br/>

（2）SDK名称：支付宝开放平台SDK<br/>
公司：支付宝（中国）网络技术有限公司<br/>
用途：在线支付<br/>
权限：网络信息 设备id IP地址 OAID IMSI。<br/>
隐私政策链接：支付宝隐私协议<br/>

（3）SDK名称：腾讯IM<br/>
包名：com.tencent.imsdk<br/>
公司：腾讯云计算（北京）有限责任公司<br/>
用途：提供用户间信息沟通、接收系统通知<br/>
权限：网络连接状态、设备型号、系统版本<br/>
隐私政策链接：IM SDK 隐私保护指引<br/>

3.2.3 服务日志信息：包括浏览记录、点击查看记录、搜索查询记录、收藏、交易、售后、关注分享信息、发布信息、以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。<br/>
当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、Idiv地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。
请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。<br/>
3.3 我们通过间接获得方式收集到的您的个人信息<br/>
您可通过本应用账号在我们提供的链接入口使用我们关联公司提供的产品或服务，为便于我们基于关联账号共同向您提供一站式服务并便于您统一进行管理，我们在本应用集中展示您的信息。您可以通过个人中心的基础信息和认证信息等功能发现、使用上述服务。
当您通过我们产品或服务使用上述服务时，您授权我们根据实际业务及合作需要从我们关联公司处接收、汇总、分析我们确认其来源合法或您授权同意其向我们提供的您的个人信息或活动信息。
如您拒绝提供上述信息或拒绝授权，可能无法正常使用本应用的相应产品或服务，或者无法展示相关信息，但不影响使用本应用浏览、参与部分不需要隐私授权的活动等核心服务。<br/>
4、为您提供安全保障<br/>
请注意，为确保用户身份真实性、向您提供更好的安全保障，您可以向我们提供身份证等身份信息完成实名认证。如您拒绝提供上述信息，可能无法使用账户管理、继续可能存在风险的交易等服务，但不会影响您使用浏览、购买商品等服务。
为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本应用相关协议规则的情况，我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br/>
5、为您提供收藏、关注与分享功能<br/>
在您浏览我们网站或客户端的过程中，您可以选择对商品及/或服务进行收藏、通过我们提供的功能组件向其他第三方分享信息。在您使用上述功能的过程中，我们会收集包括您的收藏及获取优惠券信息的记录、分享历史在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。<br/>
6、征得授权同意的例外<br/>
根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：<br/>
6.1 与个人信息控制者履行法律法规规定的义务相关的；<br/>
6.2 与国家安全、国防安全直接相关的；<br/>
6.3 与公共安全、公共卫生、重大公共利益直接相关的；<br/>
6.4 与刑事侦查、起诉、审判和判决执行等直接相关的；<br/>
6.5 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br/>
6.6 所涉及的个人信息是个人信息主体自行向社会公众公开的；<br/>
6.7 根据个人信息主体要求签订和履行合同所必需的；<br/>
6.8 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
6.9 维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；<br/>
6.10 个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；<br/>
6.11 个人信息控制者为学术研究机构，处理公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。<br/>
如我们停止运营本应用产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。<br/>
7、客服与售后功能<br/>
当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的会员身份。此时我们的客服和售后功能可能会使用您的账号信息和订单信息。<br/>
为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容(包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息)，如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。<br/>
为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。<br/>
<br/></div>
<div style={{marginLeft:0}}>&#8195;&#8195;三、我们如何使用 Cookie 和同类技术</div>
<div> 1、Cookie<br/> Cookie和同类设备信息标识技术是互联网中普遍使用的技术，当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符（以下简称“Cookie”），以收集、标识和存储您访问、使用本产品时的信息，我们承诺，不会将Cookie用于本隐私政策所述目的之外的任何其他用途，我们使用Cookie主要为了保障产品与服务的安全、高效运转，可以使我们确认您账户与交易的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复您填写表单、输入搜索内容的步骤和流程。<br/>
2、网站信标和像素标签<br/>
除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。<br/><br/>
</div>
<div style={{marginLeft:0}}>&#8195;&#8195;四、我们如何共享、转让、公开披露您的信息</div>
<div> 1、共享<br/>
我们不会与本应用服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：<br/>
1.1 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br/>
1.2 在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br/>
1.3 与关联公司间共享：为便于我们基于关联账号共同向您提供服务，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息（如为便于您使用本应用账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。<br/>
1.4 与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。<br/>
请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息，请您更加谨慎地考虑并做出决定。<br/>
目前，我们的授权合作伙伴包括以下类型：<br/>
1.4.1 广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱、手机号码，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别个人身份的统计信息（例如“男性，25-29岁，位于北京”），帮助他们了解其受众或顾客。<br/>
1.4.2 供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。<br/>
1.4.3 消息推送服务供应商：由每日互动股份有限公司提供推送技术服务，我们可能会将您的设备平台、设备厂商、设备品牌、设备识别码等设备信息，应用列表信息、网络信息以及位置相关信息提供给每日互动股份有限公司，用于为您提供消息推送技术服务。我们在向您推送消息时，我们可能会授权每日互动股份有限公司进行链路调节，相互促活被关闭的SDK推送进程，保障您可以及时接收到我们向您推送的消息。详细内容请访问《个推用户隐私政策》<br/>
1.4.4 为了向您提供一键分享服务，我们的产品集成友盟+ U-Share SDK，将收集您的设备标识信息（如IMEI/android ID/IDFA等）和您需要分享的社交账户公开信息，以便完成一键分享服务。为了您的信息安全，我们已与第三方SDK服务商进行数据安全保密约定，这些公司会严格遵守我们的数据隐私和安全要求。除非得到您的同意，我们不会与其共享您的个人身份信息。为便于您更好地了解【友盟+】采集的数据类型及用途，以及何保护您的个人信息，您可以了解【友盟+】隐私权政策。<br/>
对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。<br/>
2、转让<br/>
我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br/>
2.1 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；<br/>
2.2 在涉及合并、收购或破产清算时，如涉及个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。<br/>
3、公开披露<br/>
我们会在以下情况下，公开披露您的个人信息：<br/>
3.1 获得您明确同意后；<br/>
3.2 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。<br/>
4、共享、转让、公开披露个人信息时事先征得授权同意的例外<br/>
以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br/>
4.1 与个人信息控制者履行法律法规规定的义务相关的；<br/>
4.2 与国家安全、国防安全直接相关的；<br/>
4.3 与公共安全、公共卫生、重大公共利益直接相关的；<br/>
4.4 与刑事侦查、起诉、审判和判决执行等直接相关的；<br/>
4.5 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br/>
4.6 个人信息主体自行向社会公众公开的个人信息；<br/>
4.7 从合法公开披露的信息中心收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br/>
根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。<br/><br/></div>
<div style={{marginLeft:0}}> &#8195;&#8195;五、我们如何保护您的信息</div>
<div> 1、我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与服务器之间交换数据（如信用卡信息）时受 SSL（Secure Socket Layer）协议加密保护(即HTTPS协议)；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br/>
2、我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。<br/>
3、我们会采取合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br/>
4、互联网并非绝对安全的环境，我们强烈建议您不要使用非本应用建议的通信方式发送个人信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。<br/>
如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络本应用客服，以便我们根据您的申请采取相应措施。<br/>
请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发表动态或者在群聊天、圈子等公众场合选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。<br/>
请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。<br/><br/></div>
<div style={{marginLeft:0}}>&#8195;&#8195;六、您如何管理您的信息</div>
<div>您可以通过以下方式查阅和管理您的信息，我们会在符合法律法规要求的情形下响应您的请求：<br/>
1、访问您的个人信息<br/>
您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：<br/>
个人资料——如果您希望访问或编辑您个人资料中的出生年月日、职业、兴趣爱好以及手机号码其他资料等，您可以通过登录账户通过“我的”-“账号信息”中执行此类操作。<br/>
2、更正或补充您的个人信息<br/>
当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。<br/>
3、删除您的个人信息<br/>
您可以通过本条“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。<br/>
在以下情形中，您可以通过客服向我们提出删除个人信息的请求：<br/>
3.1 如果我们处理个人信息的行为违反法律法规；<br/>
3.2 如果我们收集、使用您的个人信息，却未征得您的明确同意；<br/>
3.3 如果我们处理个人信息的行为严重违反了与您的约定；<br/>
3.4 如果您不再使用我们的产品或服务，或您注销了账号；<br/>
3.5 如果我们不在为您提供产品或服务。<br/>
若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除法律法规另有规定，或这些主体已独立获得您的授权）。<br/>
当您或我们协助您删除相关信息后，因为使用的法律和安全技术限制，我们可能无法立即备份从备份系统之删除响应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清楚或实现匿名化。<br/>
4、改变您授权同意的范围<br/>
每个业务能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一条”）。除此之外，对于额外个人信息的收集和使用，您可以联系本应用客服申请给予或收回您的授权同意。<br/>
请注意，对于部分类型的个人信息，如实现本应用基本功能所必须的信息或者我们履行法律法规规定的义务所必须的信息，我们可能无法响应您改变授权范围的请求。当您撤回授权后，我们将不再处理相应的个人信息，但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。<br/>
5、约束信息系统自动决策<br/>
在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您可以通过客服联系我们。<br/>
6、响应您的上述请求<br/>
为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br/>
我们将在30天内做出答复。如您不满意，还可以通过本应用客服发起投诉。<br/>
对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br/>
在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br/>
6.1 与个人信息控制者履行法律法规规定的义务相关的；<br/>
6.2 与国家安全、国防安全直接相关的；<br/>
6.3 与公共安全、公共卫生、重大公共利益直接相关的；<br/>
6.4 与刑事侦查、起诉、审判和执行判决等直接相关的；<br/>
6.5 个人信息控制者有充分证据标明个人信息主体存在主管恶意或滥用权利的；<br/>
6.6 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的。<br/>
6.7 相应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益收到严重损害的；<br/>
6.8 涉及商业秘密的。<br/><br/></div>
<div style={{marginLeft:0}}> &#8195;&#8195;七、账号注销</div>
<div>我们会保留您账户上的数据（180天），如您不希望继续使用我们的产品，您可以通过：我的-设置-账号注销 向我们提交注销账号的申请。<br/>
在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的个人信息，因法律法规规定需要留存的除外。<br/>
如果您无法通过上述路径访问该等个人信息，您可以随时通过本应用客服与我们取得联系。我们将在15天内回复您的访问请求。<br/>
对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（七）响应您的上述请求”中的相关安排向您提供。<br/><br/>
</div>
<div style={{marginLeft:0}}> &#8195;&#8195;八、我们如何处理未成年人的信息</div>
<div>在数字化办公/学习、沟通与协同活动中我们推定您具有相应的民事行为能力，如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。<br/>
如果您是14周岁以下儿童的，在您使用我们的服务前，您和您的监护人应仔细阅读本隐私政策内容，确保在取得监护人的同意后，您才可在监护人的指导下使用我们的服务，确保您在使用我们的服务和进行交易时的安全。<br/>
若您是儿童的父母或其他监护人，请您关注儿童是否是在取得您的授权同意之后使用我们的服务，如您对儿童的个人信息有疑问，请与我们的个人信息保护专职部门联系。<br/>
对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
<br/><br/></div>
<div style={{marginLeft:0}}> &#8195;&#8195;九、您的信息如何在全球范围转移</div>
<div>
我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：<br/>
1 法律法规有明确规定；<br/>
2 获得您的明确授权；<br/>
3 您通过互联网进行跨境直播/发布动态等个人主动行为。<br/>
针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。<br/><br/>
</div>
<div style={{marginLeft:0}}> &#8195;&#8195;十、本隐私权政策如何更新</div>
<div>
为给您带来更好的产品和服务体验，我们持续努力改进我们的产品、服务和技术，在新的服务及业务流程变更时，我们可能会更新我们的隐私政策已告知您按照本政策所享有的权利。<br/>
对于重大变更，我们还会提供更为显著的通知（包括我们会通过小红点进行通知或向您提供弹窗提示），<br/>
未经您明确同意，我们不会削减您按照本个人信息保护政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。<br/>
对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明个人信息保护政策的具体变更内容）。<br/>
本政策所指的重大变更包括但不限于：<br/>
1、	我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br/>
2、	我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更；<br/>
3、	个人信息共享、转让或公开披露的主要对象发生变化；<br/>
4、	您参与个人信息处理方面的权利及其行使方式发生重大变化；<br/>
5、	我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br/>
6、	个人信息安全影响评估报告标明存在高风险时；<br/>
我们还会将本政策的旧版本在本应用专门页面存档，供您查阅。
<br/><br/>
</div>
<div style={{marginLeft:0}}> &#8195;&#8195;十一、如何联系我们</div>
<div>
您可以通过以下方式与我们联系，我们将在15天内回复您的请求：<br/>
1、如对本政策内容有任何疑问、意见或建议，您可通过APP中“我的”-“客服与帮助”与我们联系；<br/>
2、如发现个人信息可能被泄露，您可以通过本应用意见反馈进行举报；<br/>
公司名称：广州名图数字科技有限责任公司<br/>
办公地址：广州市番禺区洛浦街东乡村南桂路7号肇源商业城2号楼302<br/>
EMAIL ：64593218@qq.com
</div>
        </div>
      )
    }
}

export default AppStore;
