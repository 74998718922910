import React , { useState, useEffect }  from 'react';
import REQUEST from '../util/request';
import API from '../util/api';
import ZJPicturesWall from './ZJPicturesWall'

import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Drawer,
  Col,
  Row,
  InputNumber

} from 'antd';

const { TextArea } = Input;
const originData = [];
const optionA = [];
const { Option } = Select;


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = title === '类型' ? null : <TextArea autoSize={{ minRows: 1, maxRows: 5 }}/>;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [loadData, setLoadData] = useState(true);
  const [themeId, setThemeId] = useState(1);
  const [infoId, setInfoId] = useState('');
  const [infoName, setInfoName] = useState('');
  const [category, setCategory] = useState('');
  const [area, setArea] = useState('');
  const [unit, setUnit] = useState('');
  const [level, setLevel] = useState('');
  const [range, setRange] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [likeCount, setLikeCount] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [showDetailFlag, setShowDetailFlag] = useState(false);
  const [titleURL, setTitleURL] = useState([]);

  const isEditing = record => record.key === editingKey;

  const edit = record => {

    form.setFieldsValue({
      infoName:'',
      infoId: '',
      category: '',
      area: '',
      unit: '',
      level: '',
      range: '',
      introduction: '',
      likeCount: '',

      ...record,
    });
    setEditingKey(record.key);
  };

  const onClose = () => {
      setDrawerVisible(false);
      onSelectClick();
    };


  //显示题目详情列表
  const showDetail = record => {

    setInfoId(record.key);
    onSelectPng(record.key);
    setInfoName(record.infoName);
    setCategory(record.category);
    setArea(record.area);
    setUnit(record.unit);
    setLevel(record.level);
    setRange(record.range);
    setIntroduction(record.introduction);


  };

  const cancel = (key) => {

    setEditingKey('');

  };

  const onAddClick=()=>{
    setDrawerVisible(true);
    setInfoName("");
    setCategory("");
    setArea("");
    setUnit("");
    setLevel("");
    setRange("");
    setIntroduction("");
    setInfoId("");
    setTitleURL([]);
  }

  const onSelectPng=(selctInfoId)=>{
    const sendData = {themeId: themeId,infoId:selctInfoId ,picType:"01"}
    REQUEST.get(API.ZJSELECTMARKPOINTPNG, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      const newData = [];
      if (errorCode == "0000"){
        console.log(responseData);
        let resultCount = Number(responseData[0].resultCount);
            for (let i = 0; i < resultCount; i++) {
              newData.push({
                uid: responseData[i].imageId,
                name: "",
                status: responseData[i].status,
                url: responseData[i].address,

              });
            }
          setTitleURL(newData);
          setDrawerVisible(true);
          setShowDetailFlag(true);
        }
    });
}

  //查询题目
  const onSelectClick=()=>{
      const sendData = {themeId: themeId }
      REQUEST.get(API.ZJSELECTMSGBASE, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode == "0000"){
          setEditingKey('');
          let resultCount = Number(responseData[0].resultCount);
              for (let i = 0; i < resultCount; i++) {
                newData.push({
                  key: responseData[i].infoId,
                  themeId: responseData[i].themeId,
                  themeName: responseData[i].themeName,
                  infoName: responseData[i].infoName,
                  category: responseData[i].category,
                  area : responseData[i].area,
                  unit : responseData[i].unit,
                  level: responseData[i].level,
                  range: responseData[i].range,
                  introduction:responseData[i].introduction,
                  likeCount:responseData[i].likeCount,

                });
              }
            setData(newData);

          }
      });
  }

  const doLoadData=()=>{

    if (loadData != props.loadData){

      setLoadData(props.loadData);
      onSelectClick();
    }
  }
  doLoadData();

  const info = msg => {
  Modal.info({
    title: msg,

    onOk() {},
    });
  }

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(item => key.toString() === item.key);

      const sendData = row;
      sendData["answerId"] = key.toString();
      sendData["themeId"] = props.themeId;
      sendData["projectId"] = props.projectId;

        REQUEST.get(API.UPDATEQUESTIONINFO, sendData, (responseData)=>{
          let errorCode = responseData[0].errorCode;
          if (errorCode == "0000"){
            info(responseData[0].errorMsg);
            onSelectClick();
            setEditingKey('');
          }
        });
    }
    catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }


  };

  //添加题目
  const addBasicsInfo=()=>{

    if (infoName == ""){
      info("请填写非遗名称");
      return;
    }

    if (category == ""){
      info("请填写非遗类别");
      return;
    }

    // if (area == ""){
    //   info("请填申报地区！");
    //   return;
    // }


    if (unit == ""){
      info("请填写保护单位！");
      return;
    }

    if (level == ""){
      info("请填写级别！");
      return;
    }


    if (range == ""){
      info("请填写范围！");
      return;
    }
    if (introduction == ""){
      info("请填写简介！");
      return;
    }

    const sendData = {
      themeId:themeId,
      infoId:infoId,
      infoName:infoName,
      category:category,
      area:area,
      unit:unit,
      level:level,
      range:range,
      introduction:introduction,


  }
  if (infoId == ""){
    REQUEST.get(API.ZJADDBASEINFO, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      if (errorCode == "0000"){

        let newInfoId = responseData[0].infoId;

        for (let i = 0; i < titleURL.length; i++) {
          const sendData2 = {
            infoId: newInfoId,
            address:titleURL[i]["url"],
            picType:"01",
          }
          REQUEST.get(API.ZJADDMARKPOINTPNG, sendData2, (responseData)=>{
            let errorCode2 = responseData[0].errorCode;
            if (errorCode2 == "0000"){}

        })}

        info(responseData[0].errorMsg);
        setInfoName("");
        setCategory("");
        setArea("");
        setUnit("");
        setLevel("");
        setRange("");
        setIntroduction("");
        setTitleURL([]);
        setDrawerVisible(false);
        onSelectClick();
            }
    });
  }
  else{
    REQUEST.get(API.ZJUPDATEBASEINFO, sendData, (responseData)=>{
      let errorCode = responseData[0].errorCode;
      if (errorCode == "0000"){
        info(responseData[0].errorMsg)}});
        setInfoName("");
        setCategory("");
        setArea("");
        setUnit("");
        setLevel("");
        setRange("");
        setIntroduction("");
        setTitleURL([]);
        setDrawerVisible(false);
        onSelectClick();

  }

  }

  //设置级别分类
  const handleChange=(value)=>{

    if (value != null){
      setThemeId(value);
      console.log(value);
      setData([]);
    }
    else{
      setThemeId("");
    }

  }

  const handleDelete = key => {

    Modal.confirm({
      title: "确认删除该明细",
      okText: '确认',
      cancelText: '取消',
      onOk() {
      const sendData = {
        infoId: key.toString(),
        themeId:themeId,
      }
      REQUEST.get(API.ZJDELETEBASEINFO, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode == "0000"){
          info(responseData[0].errorMsg);
          onSelectClick();
      }});}})


  };

  const columns = [

    {
      title: '名称',
      dataIndex: 'infoName',
      key: 'infoName',
      width: '10%',
      editable: true,
    },
    {
      title: '类别',
      dataIndex: 'category',
      key: 'category',
      width: '5%',
      editable: true,
    },
    {
      title: '申报地区',
      dataIndex: 'area',
      key: 'area',
      width: '6%',
      editable: true,
    },
    // {
    //   title: '附件',
    //   dataIndex: 'TitleURL',
    //   key: 'TitleURL',
    //   width: '8%',
    //   editable: false,
    //   render: (_, record) => {
    //     return record.postscriptType==="图片" ? (
    //       <div>
    //     <PicturesWall buttonName="图片上传" uploadType='image/*' maxFileCount='1' ImageType='05' productId={record.productId} themeId={record.themeId} answerId={record.answerId} url={[record.TitleURL]} handleGiftAid={(value)=>setTitleURL(value)}/></div>
    //   ) :record.postscriptType==="视频" ? (
    //     <div>
    //       <PicturesWall buttonName="视频上传" uploadType='video/*' maxFileCount='1' ImageType='01' productId={record.productId} themeId={record.themeId} answerId={record.answerId} url={[record.TitleURL]} handleGiftAid={(value)=>setTitleURL(value)}/></div>
    //
    //     ):null ;
    //   }
    // },
    {
      title: '保护单位',
      dataIndex: 'unit',
      key: 'unit',
      width: '8%',
      editable: true,
    },
    {
      title: '级别',
      dataIndex: 'level',
      key: 'level',
      width: '12%',
      editable: true,
    },
    {
      title: '范围',
      dataIndex: 'range',
      key: 'range',
      width: '10%',
      editable: true,
    },
    {
      title: '简介',
      dataIndex: 'introduction',
      key: 'introduction',
      width: '28%',
      editable: true,
    },
    {
    title: '操作',
    dataIndex: 'operation',
    width: '7%',
    render: (_, record) => {

      return(
          <Space size="small">
            <a disabled={editingKey !== ''} onClick={() => showDetail(record)}>
              详情
            </a>
            <a onClick={() => handleDelete(record.key)}>
              删除
            </a>
          </Space>
        );

    }},
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false} labelCol={{ span: 1 }} wrapperCol={{ span: 22 }}>
    <Form.Item label="级别分类">
      <Space>
      <Select  allowClear={true} style={{width: 200 }}  defaultValue={"国家级非遗"}  onChange={handleChange}>
      {props.themeInfo}
      </Select>
      <Button style={{  marginLeft:20}} type="primary" onClick={() =>onSelectClick()}>
        查询
      </Button>
      <Button style={{  marginLeft:20}} type="primary" onClick={() =>onAddClick()}>
        新增
      </Button>
      </Space>
    </Form.Item>
    <Form.Item label="查询结果">
      <Table
          components={{
          body: {
            cell: EditableCell,
          },
        }}
        width={1500}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
      </Form.Item>

      <Drawer
      title="详细信息"
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={drawerVisible}
      height='80%'
      size='large'
      key="right"
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={addBasicsInfo} type="primary">
            完成
          </Button>
        </Space>
      }
    >
      <Form form={form} component={false} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
      <Form.Item label="名称" >
        <TextArea  value={infoName}  onChange={value=>setInfoName(value.target.value)}  />
      </Form.Item>
      <Form.Item label="类别" >
        <TextArea  value={category}  onChange={value=>setCategory(value.target.value)}  />
      </Form.Item>
      <Form.Item label="申报地区" >
        <TextArea  value={area}  onChange={value=>setArea(value.target.value)}  />
      </Form.Item>
      <Form.Item label="保护单位" >
        <TextArea  value={unit}  onChange={value=>setUnit(value.target.value)}  />
      </Form.Item>
      <Form.Item label="级别" >
        <TextArea  value={level}  onChange={value=>setLevel(value.target.value)}  />
      </Form.Item>
      <Form.Item label="范围" >
        <TextArea  value={range}  onChange={value=>setRange(value.target.value)}  />
      </Form.Item>
      <Form.Item label="简介" style={{height:200}} >
      <TextArea  value={introduction}  onChange={value=>setIntroduction(value.target.value)} style={{height:200}} />
      </Form.Item>
      {drawerVisible && infoName != ""?
        <Form.Item wrapperCol={{ span: 24, offset: 1}}>
         <ZJPicturesWall buttonName="图片上传" uploadType='image/*' maxFileCount='8' ImageType='01' themeId={themeId} infoName={infoName} infoId={infoId} url={titleURL} handleGiftAid={(value)=>setTitleURL(value)}/>
        </Form.Item>
        : null}

    </Form>

        </Drawer>
      </Form>

  );
}


class PageZhanJiang1 extends React.Component{

  constructor(){
    super();
    this.state = {
        drawerVisible: false,
        loadData:false,
        themeInfo:[],
  	};
  }


  componentWillMount(){
    const themeInfo = [];
    themeInfo.push(<Option key={1}>国家级非遗</Option>)
    themeInfo.push(<Option key={2}>省级非遗</Option>)
    themeInfo.push(<Option key={3}>市级非遗</Option>)
    this.setState({themeInfo:themeInfo});
  }

  getUrlParams =(name, str) => {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = str.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return "";
    }

render(){
  return (
    <div className="clearfix" style={{  marginTop:30}}>
    <EditableTable loadData={this.state.loadData} themeInfo={this.state.themeInfo}/>
    </div>
  );
}

}


export default PageZhanJiang1;
