import React , { useState,useEffect }  from 'react';
import REQUEST from '../util/request';
import API from '../util/api';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Page4 from './Page4';
import {
  Form,
  Select,
  Input,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Drawer

} from 'antd';

moment.locale('zh-cn');

const originData = [];
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const classType = [];

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [addFlag, setAddFlag] = useState(false);
  const [loadData, setLoadData] = useState(props.loadData);
  const [classification, setClassData]= useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [dealProductId, setDealProduct] = useState("");

  const isEditing = record => record.key === editingKey;



  const detail = record => {

    // form.setFieldsValue({
    //   name: '',
    //   jobTitle: '',
    //   overviews: '',
    //   introduction: '',
    //   type: '',
    //   ...record,
    // });
    // setEditingKey(record.key);
    // setAddFlag(false);


  };

  const cancel = (key) => {

    if (addFlag){
      handleDelete(key);
    }
    setEditingKey('');



  };



  const onSelectClick=()=>{

      const sendData = {numId: classification }
      REQUEST.get(API.DOSELECTPRODUCT, sendData, (responseData)=>{
        let errorCode = responseData[0].errorCode;
        const newData = [];
        if (errorCode == "0000"){
          setEditingKey('');
          let resultCount = Number(responseData[0].resultCount);
              for (let i = 0; i < resultCount; i++) {
                newData.push({
                  key: responseData[i].productId,
                  productId: responseData[i].productId,
                  productName: responseData[i].productName,
                  classification: responseData[i].classification,
                  className: responseData[i].className,
                  reamrk: responseData[i].reamrk,
                  workDate: responseData[i].workDate,
                  hasVideo: responseData[i].hasVideo,
                  hasWind: responseData[i].hasWind,
                  hasIos: responseData[i].hasIos,
                  hasAndroid: responseData[i].hasAndroid,
                  useScenario:responseData[i].useScenario,
                  explain:responseData[i].explain,


                });
              }
              setData(newData);

              if (resultCount >0){

              }
              else{
                info(responseData[0].errorMsg);
              }

          }
      });
    }



  // useEffect(()=>{
  //     // console.log(numId);
  //     onSelectClick();
  // },[loadData])



  const doLoadData=()=>{
    // if (loadData == props.loadData){
    //   setLoadData(false);
    //   onSelectClick();
    // }
  }
  // doLoadData();

  const info = msg => {
  Modal.info({
    title: msg,

    onOk() {},
    });
  }


  const handleShowDetail=(key)=>{
    setDealProduct(key);
    setDrawerVisible(true);

  }

  const handleChange1=(value)=>{

    if (value != null){
      setClassData(value);
    }
    else{
      setClassData("");
    }
  }

  const onClose = () => {
    setDrawerVisible(false);
    onSelectClick();
  };

  const handleDelete = key => {


    Modal.confirm({
      title: "确认删除该产品",
      okText: '确认',
      cancelText: '取消',
      onOk() {

        const sendData = {productId: key.toString() }
        REQUEST.get(API.DELETEPRODUCT, sendData, (responseData)=>{
          let errorCode = responseData[0].errorCode;
          const newData = [];
          if (errorCode == "0000"){
            info(responseData[0].errorMsg);
            onSelectClick();
        }});

        const newData = [...data];


        setData(newData.filter(item => item.key !== key));
      },
      });


  };


  const columns = [
    {
      title: '登记日期',
      dataIndex: 'workDate',
      key: 'workDate',
      width: '6%',
      editable: true,
    },
    {
      title: '产品分类',
      dataIndex: 'className',
      key: 'className',
      width: '8%',
      editable: true,
    },
    {
      title: '产品名称',
      dataIndex: 'productName',
      key: 'productName',
      width: '15%',
      editable: true,
    },

    {
      title: '使用场景',
      key: 'useScenario',
      dataIndex: 'useScenario',
      width: '7%',
      editable: true,
    },
    {
      title: '说明',
      key: 'explain',
      dataIndex: 'explain',
      width: '7%',
      editable: true,
    },
    {
      title: '备注',
      key: 'reamrk',
      dataIndex: 'reamrk',
      width: '7%',
      editable: true,
    },
    // {
    //   title: '宣传影片',
    //   key: 'hasVideo',
    //   dataIndex: 'hasVideo',
    //   width: '8%',
    //   editable: true,
    //
    // },
    // {
    //   title: 'windows安装包',
    //   key: 'hasWind',
    //   dataIndex: 'hasWind',
    //   width: '8%',
    //   editable: true,
    //
    // },
    // {
    //   title: 'IOS安装包',
    //   key: 'hasIos',
    //   dataIndex: 'hasIos',
    //   width: '8%',
    //   editable: true,
    //
    // },
    // {
    //   title: 'Android安装包',
    //   key: 'hasAndroid',
    //   dataIndex: 'hasAndroid',
    //   width: '8%',
    //   editable: true,
    //
    // },


    {
      title: '操作',
      dataIndex: 'operation',
      width: '13%',
      render: (_, record) => {
        // const editable = isEditing(record);
        return(

          <Space size="small">

          <a onClick={() => handleShowDetail(record.key)}>详情</a>

          <a disabled={editingKey !== ''} onClick={() => handleDelete(record.key)}>
            删除
          </a>
          </Space>)


      },
    },
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const dateFormat = 'YYYY-MM-DD';
  return (
    <Form form={form} component={false} labelCol={{ span: 1 }} wrapperCol={{ span: 22 }}>
    <Form.Item label="产品分类">
    <Space>
      <Select  allowClear={true} style={{width: 200 }}  defaultValue={props.disabled && props.role==="01"? props.tellerno:""} disabled={props.disabled} onChange={handleChange1}>
      {props.classType}
      </Select>
      <Button style={{  marginLeft:20}} type="primary" onClick={() =>onSelectClick()}>
        查询
      </Button>
      </Space>
      </Form.Item>
      <Form.Item label="查询结果">
      <Table

        width={1500}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
      </Form.Item>
      {drawerVisible?
    <Drawer
    title="详情"
    placement="bottom"
    closable={false}
    onClose={onClose}
    visible={drawerVisible}
    height='80%'
    size='large'
    key="right"
    bodyStyle={{ paddingBottom: 80 }}
    extra={
      <Space>
        <Button onClick={onClose} type="primary">
          完成
        </Button>
      </Space>
    }
  >
      <Page4  projectId={dealProductId}/>


      </Drawer> :null}

      </Form>

  );
};

class Page6 extends React.Component{

  state = {
	    loginSucc: false,
      tellerno:"",
      role:"",
      classType:[],

	};


  componentWillMount(){
      //console.log(this.props.location)//传递过来的所有参数

      //console.log(this.props.location.state.loginSucc);
      const sendData={}
      REQUEST.get(API.SELECTCLASS, sendData, (responseData)=>{

        let errorCode = responseData[0].errorCode;
        if (errorCode == "0000"){
          let resultCount = Number(responseData[0].resultCount);

              for (let i = 0; i < resultCount; i++) {
                console.log(resultCount);
                console.log(responseData[i].numId);
                console.log(responseData[i].classification);
                classType.push(<Option key={responseData[i].numId}>{responseData[i].classification}</Option>)

              }
              this.setState({classType:classType});

              console.log(this.state.classType);
            }
          });


  }


  onChange = values => {}

  render(){

    return(
          <div style={{  marginTop:100}}>

            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} >
              <EditableTable classType={this.state.classType}/>
            </Form>

          </div>);}


}


export default Page6;
